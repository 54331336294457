import React, { Component } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import moment from "moment";
import { Skeleton } from "antd";
import range from "lodash/range";
import cubejs from "../cube";
import YandexHeatMapWrap from "../charts/YandexHeatMapWrap";
import { SingleNumber } from "../components/visualizations";
import { getSingleValue } from "../utils/visualizations";
import { numberFormatter } from "../utils/helpers";
import { MainWrapper } from "../components/newDesign/MainWrapper";
import { TabMainTitle } from "../components/newDesign/TabMainTitle";
import { FilterWrapper } from "../components/newDesign/FilterWrapper";
import { FilterItem } from "../components/newDesign/FilterItem";
import { DatePickerR } from "../components/newDesign/DatePicker/DatePickerR";
import { ChartCard } from "../components/newDesign/ChartCard";
import { RowWrapper } from "../components/newDesign/RowWrapper";
import { SliderCustom } from "../components/newDesign/SliderCustom";
import { SelectList } from "../components/newDesign/SelectList";
import { rangeList } from "../constants";

const cubejsApi = cubejs({ appId: 1 });

export default class HeatmapView extends Component {
  state = {
    filtersData: {
      "TransportgraphView.groupPlace": {
        key: "TransportgraphView.groupPlace",
        title: "Улица",
        options: [],
        values: [],
        col: 8,
      },
      "TransportgraphView.weekDay": {
        key: "TransportgraphView.weekDay",
        title: "День недели",
        options: [],
        values: [],
        col: 8,
      },
    },
    dateRange: [
      moment().startOf("month").utc(6).startOf("day"),
      moment().utc(6).endOf("day"),
    ],
    timeRange: [0, 23],
  };

  changeRange = (value) =>
    this.setState({
      dateRange: [moment(value), moment()],
    });

  handleRange = (val) => {
    this.setState({ dateRange: val });
  };
  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);
    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({ filtersData });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  render() {
    const { filtersData, dateRange, timeRange } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [
      {
        member: "TransportgraphView.dateEvent",
        operator: "inDateRange",
        values: dateRange,
      },
      {
        member: "TransportgraphView.hours",
        operator: "equals",
        values: range(timeRange[0], timeRange[1] + 1).map((n) => n.toString()),
      },
    ];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });

    return (
      <MainWrapper>
        <TabMainTitle>
          Анализ входящих и исходящих транспортных потоков на период c{" "}
          <span className="date-period">
            {dateRange[0].format("DD.MM.YYYY")}{" "}
            <span style={{ color: "#fff" }}>по </span>{" "}
            {dateRange[1].format("DD.MM.YYYY")}
          </span>
        </TabMainTitle>
        <FilterWrapper>
          {filterNames.reverse().map((f, i) => {
            const filter = filtersData[f];
            return (
              <FilterItem>
                <SelectList
                  label={filter.title + ":"}
                  mode="multiple"
                  onChange={(value) => this.applyFilters(filter.key, value)}
                  size="small"
                  allowClear
                  list={filter.options}
                />
              </FilterItem>
            );
          })}
          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={(dateRange) => {
                this.setState({ dateRange });
              }}
              value={dateRange}
              size="small"
              separator="—"
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={"Шаблон:"}
              defaultValue={rangeList["Текущий месяц"].toString()}
              onChange={(value) => this.changeRange(value)}
              size="small"
              showKey="title"
              valueKey="id"
              list={Object.entries(rangeList).map(([key, value]) => ({
                id: value.toString(),
                title: key,
              }))}
            />
          </FilterItem>
          <FilterItem>
            <SliderCustom
              value={timeRange}
              min={0}
              max={23}
              marks={{
                0: {
                  style: {
                    color: "#fff",
                  },
                  label: 0,
                },
                23: {
                  style: {
                    color: "#fff",
                  },
                  label: 23,
                },
              }}
              onChange={(timeRange) => this.setState({ timeRange })}
            />
          </FilterItem>
        </FilterWrapper>

        <RowWrapper>
          <QueryRenderer
            query={{
              measures: ["TransportgraphView.countnumberSum"],
              filters,
            }}
            cubejsApi={cubejsApi}
            render={({ resultSet }) => {
              return (
                <ChartCard style={{ padding: 16 }}>
                  <SingleNumber title="Количество транспорта">
                    {resultSet &&
                      (getSingleValue(
                        resultSet,
                        "TransportgraphView.countnumberSum"
                      ) >= 1000
                        ? numberFormatter(
                            getSingleValue(
                              resultSet,
                              "TransportgraphView.countnumberSum"
                            )
                          )
                        : getSingleValue(
                            resultSet,
                            "TransportgraphView.countnumberSum"
                          ))}
                  </SingleNumber>
                </ChartCard>
              );
            }}
          />
        </RowWrapper>
        <RowWrapper>
          <ChartCard>
            <QueryRenderer
              query={{
                measures: ["TransportgraphView.countnumberSum"],
                dimensions: ["TransportgraphView.x", "TransportgraphView.y"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return <Skeleton />;
                const rawData = resultSet.rawData();
                return (
                  <YandexHeatMapWrap
                    objects={rawData}
                    sumKey="TransportgraphView.countnumberSum"
                    idDataKey="TransportgraphView.x"
                    xDataKey="TransportgraphView.x"
                    yDataKey="TransportgraphView.y"
                    dateRange={this.state.dateRange}
                    heatMap={true}
                    zoom={11}
                  />
                );
              }}
            />
          </ChartCard>
        </RowWrapper>

        {/* <Col span={18}>
              <Row gutter={20} style={{ marginBottom: 20 }}></Row>
            </Col>

            <Col span={6}> */}
        {/* <h4>Шаблон</h4> */}
        {/* <Select
              defaultValue={rangeList["Неделя"]}
              onChange={value => this.changeRange(value)}
              size="small"
              style={{ width: "100%" }}
            >
              {Object.entries(rangeList).map(([key, value]) => (
                <Option value={value}>{key}</Option>
              ))}
            </Select> */}

        {/* </Col> */}
        {/* </Col> */}
        {/* </Row> */}
      </MainWrapper>
    );
  }
}
