import React, { Component } from "react";
import { Row, Col } from "antd";
import { QueryRenderer } from "@cubejs-client/react";

import { LineChart, PieChart } from "../../components/visualizations";
import { generateLineData } from "../../utils/visualizations";
import Indicator from "../../components/Indicator";
import cubejs from "../../cube";
import styles from "./statistics.module.scss";

const cubejsApi = cubejs({ appId: 4 });

const INDICATORS1 = [
  779, 780, 781, 782,
  // 411,
  // 702,
  // 154,
  // 543,
  // 18,
  // 728,
  // 729,
  // 730,
  // 735,
  // 227,
  // 153,
  // 17,
  // 229,
  // 82,
  // 714,
  // 417
];
const INDICATORS2 = [789, 790, 791, 792, 793, 794, 795];
const INDICATORS3 = [784, 785, 786];

const UNITS = ["млрд. тенге", "%", "человек", "человек"];
const ICONS = [
  "/icons/ic-dollar-coin.svg",
  "/icons/ic-percentage.svg",
  "/icons/ic-heart-with-pulse.svg",
  "/icons/ic-team.svg",
];

const UNITS1 = ["%", "тенге", "%"];

export default class Statistics extends Component {
  state = {
    id: null,
    details: [],
    visible: false,
  };

  loadDetails = async (id) => {
    const resultSet = await cubejsApi.load({
      measures: ["PlanAndFactByYear.plan", "PlanAndFactByYear.fact"],
      dimensions: [
        "PlanAndFactByYear.ayear",
        // 'PlanAndFactByYear.theDate.month'
      ],
      filters: [
        {
          dimension: "PlanAndFactByYear.indid",
          operator: "equals",
          values: [id.toString()],
        },
      ],
      order: {
        "PlanAndFactByYear.ayear": "asc",
      },
    });
    const details = resultSet.rawData();
    this.setState({ id, details }, this.showModal);
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div>
        <div className={styles.statistics_title}>
          Статистическая панель
        </div>
        <Row type="flex" gutter={10}>
          <QueryRenderer
            query={{
              measures: [
                "PlanAndFactByYearDate.fact",
                "PlanAndFactByYearDate.prev",
              ],
              dimensions: [
                "PlanAndFactByYearDate.indid",
                "PlanAndFactByYearDate.title",
                "PlanAndFactByYearDate.untname",
                "PlanAndFactByYearDate.pername",
                "PlanAndFactByYearDate.itispos",
                "PlanAndFactByYearDate.vpytcomment",
                "PlanAndFactByYearDate.theDate.day",
              ],
              filters: [
                {
                  dimension: "PlanAndFactByYearDate.indid",
                  operator: "equals",
                  values: INDICATORS1.map((id) => id.toString()),
                },
              ],
              order: {
                "PlanAndFactByYearDate.indid": "asc",
              },
            }}
            cubejsApi={cubejsApi}
            render={({ resultSet }) => {
              if (!resultSet) return null;
              const data = resultSet.rawData();
              // return <pre>{JSON.stringify(data, null, 2)}</pre>;
              return data.map((d, i) => {
                const id = d["PlanAndFactByYearDate.indid"];
                const fact = d["PlanAndFactByYearDate.fact"];
                const prev = d["PlanAndFactByYearDate.prev"];
                const percentage = prev === 0 ? 100 : fact / prev;
                const pos = d["PlanAndFactByYearDate.itispos"];
                const greater = percentage >= 1;
                const isPositive = !!pos ? percentage >= 1 : percentage < 1;
                // cons isNegative = !!neg
                // return (
                //   <div>
                //     <pre>{JSON.stringify(percentage, null, 2)} =======</pre>
                //   </div>
                // );
                return (
                  <Col span={12} key={id} className={styles.statistics_column}>
                    <Row
                      type="flex"
                      align="middle"
                      className={styles.statistics_column_row}
                    >
                      <Col span={14}>
                        <QueryRenderer
                          query={{
                            measures: [
                              // 'PlanAndFactByYear.plan',
                              "PlanAndFactByYear.fact",
                            ],
                            dimensions: [
                              // 'PlanAndFactByYear.ayear'
                              i === 0 || i === 1
                                ? "PlanAndFactByYear.theDate.year"
                                : "PlanAndFactByYear.theDate.month",
                              // 'PlanAndFactByYear.untname'
                            ],
                            filters: [
                              {
                                dimension: "PlanAndFactByYear.indid",
                                operator: "equals",
                                values: [INDICATORS1[i].toString()],
                              },
                            ],
                          }}
                          cubejsApi={cubejsApi}
                          render={({ resultSet }) => {
                            // if (!resultSet) return null;
                            // const data = resultSet.rawData();
                            // return <pre>{JSON.stringify(data, null, 2)}</pre>;
                            return (
                              <LineChart
                                loading={!resultSet}
                                id={`PlanAndFactByYearLine1${i}`}
                                {...generateLineData(resultSet)}
                                values={[
                                  {
                                    title: "Факт",
                                    key: "PlanAndFactByYear.fact",
                                    color: isPositive ? "#83FF49" : "#E94D4D",
                                  },
                                ]}
                                dateAxis
                                rotate
                                filled
                                showScrollbar={false}
                                valueText={UNITS[i]}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col span={10}>
                        <Indicator
                          title={d["PlanAndFactByYearDate.title"]}
                          icon={ICONS[i]}
                          value={d["PlanAndFactByYearDate.fact"]}
                          unit={d["PlanAndFactByYearDate.untname"]}
                          percentage={percentage.toFixed(1)}
                          direction={greater ? "up" : "down"}
                          isPositive={isPositive}
                          period={d["PlanAndFactByYearDate.vpytcomment"]}
                          // onClick={() => this.loadDetails(id)}
                        />
                      </Col>
                    </Row>
                  </Col>
                );
              });
            }}
          />
        </Row>

        <Row type="flex">
          <Col span={24} className={styles.physical_volume_column}>
            <h4 className={styles.physical_volume_column_title}            >
              Индекс физического объема
            </h4>
            <Row className={styles.physical_volume_column_content}>
              <Col span={10}>
                  <h4 style={{ textAlign: "center" }}>
                    За период январь-октябрь 2019 (%)
                  </h4>
                  <QueryRenderer
                    query={{
                      measures: ["PlanAndFactByYear.fact"],
                      dimensions: ["PlanAndFactByYear.title"],
                      filters: [
                        {
                          dimension: "PlanAndFactByYear.indid",
                          operator: "equals",
                          values: INDICATORS2.map((id) => id.toString()),
                        },
                      ],
                      order: {
                        "PlanAndFactByYear.indid": "asc",
                      },
                    }}
                    cubejsApi={cubejsApi}
                    render={({ resultSet }) => {
                      return (
                        <PieChart
                          loading={!resultSet}
                          id="PlanAndFactByYearPie"
                          data={resultSet ? resultSet.rawData() : []}
                          nameKey="PlanAndFactByYear.title"
                          dataKey="PlanAndFactByYear.fact"
                          height="400px"
                        />
                      );
                    }}
                  />
              </Col>

              <Col span={14}>
                  <h4 style={{ textAlign: "center" }}>По месяцам (%)</h4>
                  <QueryRenderer
                    query={{
                      measures: ["PlanAndFactByYear.fact"],
                      dimensions: [
                        "PlanAndFactByYear.title",
                        "PlanAndFactByYear.theDate.day",
                      ],
                      filters: [
                        {
                          dimension: "PlanAndFactByYear.indid",
                          operator: "equals",
                          values: INDICATORS2.map((n) => n.toString()),
                        },
                      ],
                    }}
                    cubejsApi={cubejsApi}
                    render={({ resultSet }) => {
                      // if (!resultSet) return null;
                      // const data = resultSet.rawData();
                      // return <pre>{JSON.stringify(generateLineData(resultSet), null, 2)}</pre>;
                      return (
                        <LineChart
                          loading={!resultSet}
                          id="PlanAndFactByYearLine2"
                          {...generateLineData(resultSet)}
                          dateAxis
                          rotate
                          showScrollbar={false}
                          // valueText={UNITS[i]}
                          height="400px"
                        />
                      );
                    }}
                  />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* {id && (
          <>
            <Modal
              title="Детализация"
              visible={visible}
              onCancel={this.handleCancel}
              footer={null}
              width={1000}
            >
              <LineGraph
                data={details.map(d => ({
                  plan: d['PlanAndFactByYear.plan'],
                  fact: d['PlanAndFactByYear.fact'],
                  year: d['PlanAndFactByYear.ayear']
                }))}
                id={id.toString()}
                unit="indicator.unitType"
              />
            </Modal>
          </>
        )} */}

        <Row type="flex" gutter={10}>
          <QueryRenderer
            query={{
              measures: [
                "PlanAndFactByYearDate.fact",
                "PlanAndFactByYearDate.prev",
              ],
              dimensions: [
                "PlanAndFactByYearDate.indid",
                "PlanAndFactByYearDate.title",
                "PlanAndFactByYearDate.untname",
                "PlanAndFactByYearDate.pername",
                "PlanAndFactByYearDate.itispos",
                "PlanAndFactByYearDate.vpytcomment",
                "PlanAndFactByYearDate.theDate.day",
              ],
              filters: [
                {
                  dimension: "PlanAndFactByYearDate.indid",
                  operator: "equals",
                  values: INDICATORS3.map((id) => id.toString()),
                },
              ],
              order: {
                "PlanAndFactByYearDate.indid": "asc",
              },
            }}
            cubejsApi={cubejsApi}
            render={({ resultSet }) => {
              if (!resultSet) return null;
              const data = resultSet.rawData();
              // return <pre>{JSON.stringify(data, null, 2)}</pre>;
              return data.map((d, i) => {
                const id = d["PlanAndFactByYearDate.indid"];
                const fact = d["PlanAndFactByYearDate.fact"];
                const prev = d["PlanAndFactByYearDate.prev"];
                const percentage = prev === 0 ? 100 : fact / prev;
                const pos = d["PlanAndFactByYearDate.itispos"];
                const greater = percentage >= 1;
                const isPositive = !!pos ? percentage >= 1 : percentage < 1;
                // return (
                //   <div>
                //     <pre>{JSON.stringify(percentage, null, 2)} =======</pre>
                //   </div>
                // );
                return (
                  <Col span={8} key={id}>
                    <div className={styles.inflation_item}>
                    <Indicator
                      title={d["PlanAndFactByYearDate.title"]}
                      icon={ICONS[0]}
                      value={d["PlanAndFactByYearDate.fact"]}
                      unit={d["PlanAndFactByYearDate.untname"]}
                      percentage={percentage.toFixed(1)}
                      direction={greater ? "up" : "down"}
                      isPositive={isPositive}
                      period={d["PlanAndFactByYearDate.vpytcomment"]}
                      // onClick={() => this.loadDetails(id)}
                    >
                      <QueryRenderer
                        query={{
                          measures: [
                            // 'PlanAndFactByYear.plan',
                            "PlanAndFactByYear.fact",
                          ],
                          dimensions: [
                            // 'PlanAndFactByYear.ayear'
                            "PlanAndFactByYear.theDate.month",
                            // 'PlanAndFactByYear.untname'
                          ],
                          filters: [
                            {
                              dimension: "PlanAndFactByYear.indid",
                              operator: "equals",
                              values: [INDICATORS3[i].toString()],
                            },
                          ],
                        }}
                        cubejsApi={cubejsApi}
                        render={({ resultSet }) => {
                          if (!resultSet) return null;
                          // const data = resultSet.rawData();
                          // return <pre>{JSON.stringify(data, null, 2)}</pre>;
                          return (
                            <LineChart
                              loading={!resultSet}
                              id={`PlanAndFactByYearLine2${i}`}
                              {...generateLineData(resultSet)}
                              values={[
                                {
                                  title: "Факт",
                                  key: "PlanAndFactByYear.fact",
                                  color: isPositive ? "#83FF49" : "#E94D4D",
                                },
                              ]}
                              dateAxis
                              rotate
                              filled
                              showScrollbar={false}
                              valueText={UNITS1[i]}
                            />
                          );
                        }}
                      />
                    </Indicator>
                    </div>
                  </Col>
                );
              });
            }}
          />
        </Row>
      </div>
    );
  }
}
