import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";

import cubejs from "../cube";
import LineChartWrap from "../charts/LineChartWrap";
import YandexMapWrap from "../charts/YandexMapWrap";
import Loader from "../components/Loader";
import { MainWrapper } from "../components/newDesign/MainWrapper";
import { TabMainTitle } from "../components/newDesign/TabMainTitle";
import { FilterWrapper } from "../components/newDesign/FilterWrapper";
import { FilterItem } from "../components/newDesign/FilterItem";
import { DatePickerR } from "../components/newDesign/DatePicker/DatePickerR";
import { SelectList } from "../components/newDesign/SelectList";
import { RowWrapper } from "../components/newDesign/RowWrapper";
import { ChartCard } from "../components/newDesign/ChartCard";

const cubejsApi = cubejs({ appId: 1 });

export default class Ecology2 extends Component {
  constructor() {
    super();
    this.state = {
      filtersData: {
        "DStation.pnzNumber": {
          key: "DStation.pnzNumber",
          title: "Станция",
          options: [],
          values: [],
        },
        "SmartECOKazgidromet.measureNameRu": {
          key: "SmartECOKazgidromet.measureNameRu",
          single: true,
          title: "Элементы",
          options: [],
          values: [],
        },
        "DStation.locality": {
          key: "DStation.locality",
          single: true,
          title: "Город",
          options: [],
          values: [],
        },
      },
      filters: [
        {
          member: "SmartECOKazgidromet.dateTime",
          operator: "inDateRange",
          values: [moment().subtract(2, "d"), moment()],
        },
      ],
      cardData: {
        lineChartData: [],
        series: [],
        data: [],
        resultSet: [],
      },
      mapData: [],
      additionalInformation: ["SmartECOKazgidromet.postAdditionalInformation"],
      byElements: false,
      currentArea: {
        pnzNumber: [],
        locality: [],
        measureNameRu: [],
      },
      range: [moment().subtract(2, "d"), moment()],
      loading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.getFiltersData();
    this.getCardData();
    this.getMapData();
  }

  getFiltersData = async () => {
    const { filtersData, filters } = this.state;
    let newFiltersData = filtersData;
    const filterNames = Object.keys(filtersData);

    let reqs = [];
    filterNames.forEach((f) => {
      let newFilters = filters.filter((e) => e.member !== f);
      reqs.push(
        cubejsApi.load(
          newFilters.length !== 0
            ? {
                dimensions: [f],
                filters: newFilters,
              }
            : {
                dimensions: [f],
              }
        )
      );
    });

    const responses = await Promise.all(reqs);
    responses.forEach((res, i) => {
      newFiltersData = {
        ...newFiltersData,
        [filterNames[i]]: {
          ...newFiltersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({ filtersData: newFiltersData });
  };

  getMapData = () => {
    const { filters, additionalInformation } = this.state;
    let request = [
      {
        measures: [],
        dimensions: [
          "DStation.pnzNumber",
          "DStation.id",
          "DStation.x",
          "DStation.y",
          "DStation.type",
          "DStation.dataType",
          "SmartECOKazgidromet.postAdditionalInformation",
        ],
        filters,
        limit: 50000,
      },
      {
        measures: ["SmartECOKazgidromet.valueMax"],
        dimensions: [
          "SmartECOKazgidromet.measureNameRu",
          "SmartECOKazgidromet.maxpokazatel",
          ...additionalInformation,
        ],
        filters,
        limit: 50000,
      },
    ];

    Promise.all(request.map((e) => cubejsApi.load(e))).then((r) => {
      let measures = _.groupBy(
        r[1].rawData(),
        "SmartECOKazgidromet.postAdditionalInformation"
      );

      let keys = [];
      Object.keys(measures).forEach((el) => {
        let data = { post: el };
        measures[el].some(
          (e) =>
            e["SmartECOKazgidromet.maxpokazatel"] !== null &&
            e["SmartECOKazgidromet.valueMax"] >
              e["SmartECOKazgidromet.maxpokazatel"]
        )
          ? (data["color"] = "#f44336")
          : (data["color"] = "#32CD66");

        keys.push(data);
      });

      let data = r[0].rawData().map((el) => ({
        ...el,
        "DStation.pnzNumber": el["DStation.pnzNumber"],
        "DStation.type": el["DStation.type"],
        "DStation.dataType": el["DStation.dataType"],
        color: keys.find(
          (e) => e.post === el["SmartECOKazgidromet.postAdditionalInformation"]
        ).color,
        ballon: {
          hintContent: el["DStation.pnzNumber"],
        },
      }));

      this.setState({ mapData: data });
    });
  };

  async getCardData() {
    const { filters, additionalInformation, byElements } = this.state;
    let request = {
      measures: ["SmartECOKazgidromet.value"],
      dimensions: [
        "SmartECOKazgidromet.measureNameRu",
        "SmartECOKazgidromet.maxpokazatel",
        "SmartECOKazgidromet.dateTime.hour",
      ],
      filters,
      order: {
        "SmartECOKazgidromet.maxpokazatel": "asc",
      },
      limit: 50000,
    };
    if (byElements) request.dimensions.push(additionalInformation[0]);
    let resultSet = await cubejsApi.load(request);

    let lineChartData = resultSet.chartPivot().map((d) => {
      const obj = { ...d, x: new Date(d.x) };
      if (obj.x[2] === 0) {
        obj["SmartECOKazgidromet.value"] = null;
      }
      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === "number" && value === 0) {
          obj[key] = null;
        }
      }
      return obj;
    });

    this.setState({
      cardData: {
        lineChartData,
        resultSet,
        series: resultSet ? resultSet.seriesNames() : [],
        data: resultSet.rawData(),
      },
      loading: false,
    });
  }

  applyFilters = (filter, values, single) => {
    let { filtersData, filters, currentArea } = this.state;
    let newFilters = [...filters].filter((e) => e.member !== "DStation.id");

    values = Array.isArray(values) ? values : [values];
    values = values.includes(undefined) ? [] : values;

    if (values.length !== 0 && !newFilters.find((x) => x.member === filter)) {
      newFilters.push({
        member: filter,
        operator: "equals",
        values: values,
      });
    } else if (
      newFilters.find((x) => x.member === filter) &&
      values.length !== 0
    ) {
      newFilters[newFilters.findIndex((x) => x.member === filter)].values =
        values;
    } else {
      newFilters.splice(
        newFilters.findIndex((x) => x.member === filter),
        1
      );
    }

    if (filters.find((e) => e.member !== "DStation.id")) {
      currentArea.pnzNumber = [];
      this.setState({});
    }

    this.setState(
      {
        filtersData: {
          ...filtersData,
          [filter]: {
            ...filtersData[filter],
            values,
          },
        },
        additionalInformation: values
          ? ["SmartECOKazgidromet.postAdditionalInformation"]
          : [],
        currentArea: {
          pnzNumber: filters.find((e) => e.member !== "DStation.id")
            ? filter === "DStation.pnzNumber"
              ? values
              : []
            : this.state.currentArea.pnzNumber,
          locality:
            filter === "DStation.locality"
              ? values
              : this.state.currentArea.locality,
          measureNameRu:
            filter === "SmartECOKazgidromet.measureNameRu"
              ? values
              : this.state.currentArea.measureNameRu,
        },

        filters: newFilters,
        loading: true,
        mapData: [],
      },
      () => this.checkByElements()
    );
  };

  checkByElements = () => {
    let { byElements, filters } = this.state;
    let newByElements = byElements;
    if (
      filters.find((e) => e.member === "SmartECOKazgidromet.measureNameRu") &&
      !filters.find((e) => e.member === "DStation.pnzNumber") &&
      !filters.find((e) => e.member === "DStation.locality")
    )
      newByElements = true;
    else newByElements = false;

    this.setState(
      {
        byElements: newByElements,
        loading: true,
      },
      () => this.getData()
    );
  };

  onChangeDatePicker = (range) => {
    let { filters } = this.state;
    let newFilters = [...filters].filter((e) => e.member !== "DStation.id");
    newFilters[
      filters.findIndex((x) => x.member === "SmartECOKazgidromet.dateTime")
    ].values = range;
    this.setState(
      { filters: newFilters, range, loading: true, mapData: [] },
      () => this.getData()
    );
  };

  loadDetails = (items) => {
    let { filters, mapData } = this.state;
    this.setState(
      {
        filters: [
          ...filters.filter((e) => e.member !== "DStation.id"),
          {
            member: "DStation.id",
            operator: "equals",
            values: Array.isArray(items)
              ? items.map((el) => el["DStation.id"].toString())
              : [items.toString()],
          },
        ],
        currentArea: {
          ...this.state.currentArea,
          pnzNumber: Array.isArray(items)
            ? _.uniqBy(items, "DStation.pnzNumber").map(
                (e) => e["DStation.pnzNumber"]
              )
            : [
                mapData.find((e) => e["DStation.id"] === items)[
                  "DStation.pnzNumber"
                ],
              ],
        },
        byElements: true,
        loading: true,
      },
      () => this.getCardData()
    );
  };

  returnMapColor(value, maxValue) {
    if (maxValue === 0) return "#32CD66";
    else if (value > maxValue) return "#f44336";
    else return "#32CD66";
  }

  returnCurrentArea() {
    let { currentArea } = this.state;

    return currentArea.pnzNumber.length !== 0 ||
      currentArea.locality.length !== 0 ||
      currentArea.measureNameRu.length
      ? currentArea.pnzNumber.length !== 0
        ? " " + currentArea.pnzNumber.join()
        : currentArea.locality.length !== 0
        ? " Средние показатели по " + currentArea.locality.join()
        : " Показатели по Атырауской области"
      : " Средние показатели по Атырауской области";
  }

  returnTitle(color) {
    switch (color) {
      case "#32CD66":
        return "Низкий уровень загрязнения";
      case "#f44336":
        return "Критический уровень загрязнения";
      case "#2196f3":
        return "Станция";
      default:
        break;
    }
  }

  returnMarkerColor(source, type) {
    let color;
    if ((source === "Казгидромет") & (type === "автоматика")) {
      color = "#B3DDCC";
    } else if ((source === "Казгидромет") & (type === "ручные")) {
      color = "#3D91BE";
    } else if ((source === "Смарт ЭКО") & (type === "автоматика")) {
      color = "#9c27b0";
    } else if ((source === "Смарт ЭКО") & (type === "ручные")) {
      color = "#F997BA";
    }
    return color;
  }

  render() {
    const { filtersData, range, cardData } = this.state;

    return (
      <MainWrapper>
        <TabMainTitle>
          Загрязнение воздуха: средние показатели по Атырауской области на
          период с{" "}
          <span className="date-period">
            {range[0].format("DD.MM.YYYY")}{" "}
            <span style={{ color: "#fff" }}>по </span>{" "}
            {range[1].format("DD.MM.YYYY")}
          </span>
        </TabMainTitle>

        <FilterWrapper>
          <FilterItem>
            <SelectList
              label={filtersData["DStation.pnzNumber"].title + ":"}
              defaultValue={this.state.currentArea.pnzNumber}
              mode="multiple"
              onChange={(value) => {
                this.applyFilters(
                  filtersData["DStation.pnzNumber"].key,
                  value,
                  filtersData["DStation.pnzNumber"].single
                );
              }}
              allowClear
              size="small"
              list={filtersData["DStation.pnzNumber"].options}
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={
                filtersData["SmartECOKazgidromet.measureNameRu"].title + ":"
              }
              onChange={(value) => {
                this.applyFilters(
                  filtersData["SmartECOKazgidromet.measureNameRu"].key,
                  value,
                  filtersData["SmartECOKazgidromet.measureNameRu"].single
                );
              }}
              allowClear
              size="small"
              list={filtersData["SmartECOKazgidromet.measureNameRu"].options}
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={filtersData["DStation.locality"].title + ":"}
              onChange={(value) => {
                this.applyFilters(
                  filtersData["DStation.locality"].key,
                  value,
                  filtersData["DStation.locality"].single
                );
              }}
              allowClear
              size="small"
              list={filtersData["DStation.locality"].options}
            />
          </FilterItem>
          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={(range) => {
                this.onChangeDatePicker(range);
              }}
              value={range}
              size="small"
              separator="—"
            />
          </FilterItem>
        </FilterWrapper>
        <RowWrapper>
          <div style={{ width: "30%" }}>
            <YandexMapWrap
              objects={this.state.mapData}
              idDataKey="DStation.id"
              xDataKey="DStation.x"
              center={[47.1167, 51.8833]}
              yDataKey="DStation.y"
              height="550px"
              zoom={5}
              handleClick={(items) => {
                this.loadDetails(items);
              }}
              onClusterClick={(items) => {
                this.loadDetails(items);
              }}
            />
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                flexWrap: "wrap",
              }}
            >
              {Array.from(new Set(this.state.mapData.map((el) => el.color)))
                .map((el) => ({
                  color: el,
                  "DStation.dataType": this.state.mapData.find(
                    (e) => e.color === el
                  )["DStation.dataType"],
                  "DStation.type": this.state.mapData.find(
                    (e) => e.color === el
                  )["DStation.type"],
                }))
                .map((item) => (
                  <div
                    style={{
                      display: "flex",
                      marginRight: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: item.color,
                        height: "15px",
                        borderRadius: "10",
                        width: "15px",
                        marginRight: "5px",
                      }}
                    ></div>
                    <div>{this.returnTitle(item.color)}</div>
                  </div>
                ))}
            </div>
          </div>
          <div
            style={{
              width: "69%",
              marginLeft: 10,
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {!this.state.loading ? (
              <>
                {cardData.series.length !== 0
                  ? cardData.series.map((s, i) => {
                      let maxValue = this.state.single
                        ? cardData.data[0]["SmartECOKazgidromet.maxpokazatel"]
                        : cardData.data.find((el) =>
                            el["SmartECOKazgidromet.measureNameRu"].includes(
                              s.title.split(", ")[0]
                            )
                          )["SmartECOKazgidromet.maxpokazatel"];

                      let filteredData = cardData.data
                        .filter((el) =>
                          el["SmartECOKazgidromet.measureNameRu"].includes(
                            s.title.split(", ")[0]
                          )
                        )
                        .map((el) => Number(el["SmartECOKazgidromet.value"]));
                      let maxFilteredValue = Math.max.apply(null, filteredData);
                      if (maxFilteredValue >= 1000000) {
                        return (
                          <ChartCard style={{ width: "30%", flex: "unset" }}>
                            <h3
                              style={{
                                textAlign: "center",
                                color: "#b9b9b9",
                              }}
                            >
                              Данные не обновлены
                            </h3>
                            <h3
                              style={{
                                textAlign: "center",
                                color: "#b9b9b9",
                              }}
                            >
                              ({s.title.split(", ")[0]})
                            </h3>
                          </ChartCard>
                        );
                      }
                      return (
                        <ChartCard
                          title={
                            this.state.byElements
                              ? s.title.split(", ")[0] +
                                " - " +
                                s.title.split(", ")[2]
                              : s.title.split(", ")[0]
                          }
                          style={{ width: "30%", flex: "unset" }}
                        >
                          <LineChartWrap
                            critical={maxValue}
                            id={`SmartECOKazgidromet${i}`}
                            data={cardData.lineChartData}
                            category="x"
                            yUnit={s.title.split(", ")[1].trim()}
                            values={[{ ...s, title: s.title.split(", ")[0] }]}
                            height={300}
                            color="#00bcd4"
                            scroll={{ start: 0, end: 1 }}
                            showLegend={false}
                            totals={
                              maxValue != null
                                ? [
                                    {
                                      key: Number(maxValue),
                                      title: "Критическое значение",
                                      value: maxValue,
                                      color: "red",
                                    },
                                  ]
                                : []
                            }
                          />
                        </ChartCard>
                      );
                    })
                  : "Данные по выбранной станции отсутствуют"}
              </>
            ) : (
              <Loader imgSize={50} fontSize={25} />
            )}
          </div>
        </RowWrapper>
      </MainWrapper>
    );
  }
}
