import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";

const COLORS = ["#388E3C", "#FBC02D", "#0288d1", "#F44336", "#8E24AA"];

export default class HorizontalStackedBarChart extends Component {
  state = { visible: false, items: [] };

  componentDidMount() {
    am4core.options.commercialLicense = true;
    const { id, data, category, categoryText, values, valueText, query } =
      this.props;
    let chart = am4core.create(id, am4charts.XYChart);

    chart.language.locale = am4lang_ru_RU;
    chart.data = data;

    // Legend
    chart.legend = new am4charts.Legend();
    // chart.legend.useDefaultMarker = true;
    chart.legend.fontSize = 11;
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 11;
    markerTemplate.height = 11;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);

    // Create axes
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = category;
    if (categoryText) {
      categoryAxis.title.text = categoryText;
    }
    categoryAxis.title.fill = "#000";

    // label
    let label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.wrap = true;
    label.fontSize = 12;
    label.maxWidth = 260;
    // label.align = "left";
    label.hideOversized = true;

    chart.events.on("datavalidated", function (ev) {
      let chart = ev.target;
      let categoryAxis = chart.yAxes.getIndex(0);
      let adjustHeight = chart.data.length * 20 - categoryAxis.pixelHeight;
      let targetHeight = chart.pixelHeight + adjustHeight;
      chart.svgContainer.htmlElement.style.height = targetHeight + "px";
    });

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    if (valueText) {
      valueAxis.title.text = valueText;
    }
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;

    // Create series
    values.forEach((value, i) => {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = value.key;
      series.dataFields.valueXShow = "totalPercent";
      series.dataFields.categoryY = category;
      series.name = value.title;
      series.tooltipText = "{name}: [bold]{valueX}[/]";
      series.stacked = true;

      // click
      series.columns.template.events.on("hit", (ev) => {
        const category = ev.target.dataItem.categoryY;
        if (Object.keys(query).length) this.loadDetails(category);
      });

      // colors
      series.columns.template.stroke = am4core.color(
        value.color ? value.color : COLORS[i]
      );
      series.columns.template.fill = am4core.color(
        value.color ? value.color : COLORS[i]
      );
    });

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    this.chart = chart;
  }

  loadDetails = async (categoryY) => {
    const { category, cubejsApi, query } = this.props;
    const resultSet = await cubejsApi.load({
      ...query,
      filters: [
        {
          dimension: category,
          operator: "equals",
          values: [categoryY],
        },
      ],
    });
    const items = resultSet.rawData();
    this.setState({
      visible: true,
      items,
    });
  };

  hideDetails = (e) => {
    this.setState({
      visible: false,
    });
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { visible, items } = this.state;
    const { id, height, renderDetail } = this.props;
    return (
      <div>
        <div style={{ position: "relative", height, overflowY: "auto" }}>
          <div id={id} style={{ width: "100%", height }} />
        </div>
        <Modal
          title="Детализация"
          visible={visible}
          onCancel={this.hideDetails}
          width="96%"
          footer={null}
          bodyStyle={{
            height: "88vh",
            overflowY: "auto",
          }}
          centered
        >
          {renderDetail(items)}
        </Modal>
      </div>
    );
  }
}

HorizontalStackedBarChart.defaultProps = {
  category: "category",
  height: "480px",
  cubejsApi: null,
  query: {},
  renderDetail: () => {},
};

HorizontalStackedBarChart.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  category: PropTypes.string,
  categoryText: PropTypes.string,
  values: PropTypes.array,
  valueText: PropTypes.string,
  height: PropTypes.number,
  cubejsApi: PropTypes.object,
  query: PropTypes.object,
  renderDetail: PropTypes.func,
};
