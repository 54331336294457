import React from "react";
import { Slider } from "antd";
import styles from "./slider.module.scss";

export default function SliderCustom({
  disabled = false,
  marks,
  max,
  min,
  range = true,
  value,
  onChange,
  ...rest
}) {
  return (
    <Slider
      className={styles.slider_custom}
      disabled={disabled}
      marks={marks}
      max={max}
      min={min}
      range={range}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
}
