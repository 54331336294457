import React, { Component } from "react";
import { Row, Col } from "antd";
import cubejs from "../cube";
import YandexHeatMapWrap from "../charts/YandexHeatMapWrap";
import moment from "moment";
import LoadingCss from "../common/LoadingCss";
import { TabMainTitle } from "../components/newDesign/TabMainTitle";
import { FilterWrapper } from "../components/newDesign/FilterWrapper";
import { FilterItem } from "../components/newDesign/FilterItem";
import { SelectList } from "../components/newDesign/SelectList";
import { DatePickerR } from "../components/newDesign/DatePicker/DatePickerR";
import { MainWrapper } from "../components/newDesign/MainWrapper";

const cubejsApi = cubejs({ appId: 1 });

export default class DtpMap extends Component {
  state = {
    range: [
      moment().startOf("month").utc(6).startOf("day"),
      moment().utc(6).endOf("day"),
    ],
    data: [],
    filtersData: {
      "DtpKgf.fd1r07p2": {
        key: "DtpKgf.fd1r07p2",
        title: "Время суток",
        options: [],
        values: [],
      },
      "DtpKgf.fd1r071p1": {
        key: "DtpKgf.fd1r071p1",
        title: "Условие ДТП",
        options: [],
        values: [],
      },
      "DtpKgf.incision": {
        key: "DtpKgf.incision",
        title: "Населенный пункт",
        options: [],
        values: [],
      },
    },
    loading: true,
  };

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({ filtersData });
    this.getData();
  }

  getData = () => {
    const { filtersData } = this.state;
    let filters = [];
    const filterNames = Object.keys(filtersData);
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });
    let query = {
      dimensions: ["DtpKgf.id", "DtpKgf.x", "DtpKgf.y"],
      filters: [
        {
          member: "DtpKgf.rtaDate",
          operator: "inDateRange",
          values: this.state.range,
        },
        ...filters,
      ],
    };

    this.setState({ loading: true });

    cubejsApi
      .load(query)
      .then((r) => {
        //console.log(r)
        let data = r.rawData();
        data = data.map((e) => ({
          ...e,
          "DtpKgf.x": e["DtpKgf.x"].toString().substring(0, 10),
          "DtpKgf.y": e["DtpKgf.y"].toString().substring(0, 10),
        }));
        console.log("Appp", data);
        this.setState({ data });
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  handleRange = (val) => {
    this.setState({ range: val }, this.getData);
  };

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState(
      {
        filtersData: {
          ...filtersData,
          [filter]: {
            ...filtersData[filter],
            values,
          },
        },
        loading: true,
      },
      () => this.getData()
    );
  };

  render() {
    const { filtersData, loading, range } = this.state;

    const filterNames = Object.keys(filtersData);

    return (
      <MainWrapper>
        <TabMainTitle>
          Карта опасных участков на период c{" "}
          <span className="date-period">
            {range[0].format("DD.MM.YYYY")}{" "}
            <span style={{ color: "#fff" }}>по </span>{" "}
            {range[1].format("DD.MM.YYYY")}
          </span>
        </TabMainTitle>
        <FilterWrapper>
          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={this.handleRange}
              value={this.state.range}
              size="small"
              separator="-"
            />
          </FilterItem>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];

            return (
              <FilterItem>
                <SelectList
                  label={filter.title + ":"}
                  mode="multiple"
                  onChange={(value) => this.applyFilters(filter.key, value)}
                  size="small"
                  allowClear
                  list={filter.options}
                />
              </FilterItem>
            );
          })}
        </FilterWrapper>

        <Row>
          <Col span={24}>
            {loading ? (
              <LoadingCss />
            ) : (
              <YandexHeatMapWrap
                objects={this.state.data}
                showDefaultCity
                idDataKey="DtpKgf.id"
                xDataKey="DtpKgf.x"
                yDataKey="DtpKgf.y"
                dateRange={range}
                heatMap={true}
                zoom={11}
                height="70vh"
              />
            )}
          </Col>
        </Row>
      </MainWrapper>
    );
  }
}
