import React, { Component } from "react";
import { Row, Col, Select, DatePicker, Button } from "antd";
import cubejs from "../../cube";
const cubejsApi = cubejs({ appId: 1 });

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      regions: [],
      districts: [],
    };
  }

  async componentDidMount() {
    const resultSet = await cubejsApi.load({
      dimensions: ["Appeals.category"],
    });
    let categories = resultSet
      .rawData()
      .map((e) => e["Appeals.category"])
      .filter((e) => e);
    const data = await cubejsApi.load({
      dimensions: ["Appeals.region"],
    });
    let regions = data
      .rawData()
      .map((e) => e["Appeals.region"])
      .filter((e) => e);
    const districtsData = await cubejsApi.load({
      dimensions: ["Appeals.district"],
    });
    let districts = districtsData
      .rawData()
      .map((e) => e["Appeals.district"])
      .filter((e) => e);
    this.setState({ categories, regions, districts });
  }

  render() {
    return (
      <Row gutter={5} type="flex" justify="start">
        <Col span={9} style={{ display: "flex", alignItems: "center" }}>
          <label style={{ marginRight: 10 }}>
            <b>ЗА ПЕРИОД</b>
          </label>
          <DatePicker.RangePicker
            onChange={this.props.handleRange}
            separator="—"
            value={this.props.range}
            showTime={{
              secondStep: 60,
              minuteStep: 15,
            }}
            allowClear={false}
            size="small"
            style={{ marginRight: "auto", marginBottom: "5px" }}
          />
        </Col>
        <Col span={4}>
          <Select
            placeholder="Исполнительный орган"
            onChange={this.props.changeCategories}
            style={{ width: "100%" }}
            allowClear
            value={this.props.category}
          >
            {this.state.categories.map((e, index) => (
              <Select.Option key={index} value={e}>
                {e}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <Select
            placeholder="Регион"
            onChange={this.props.changeRegion}
            style={{ width: "100%" }}
            allowClear
            value={this.props.region}
          >
            {this.state.regions.map((e, index) => (
              <Select.Option key={index} value={e}>
                {e}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <Select
            placeholder="Населенный пункт"
            onChange={this.props.changeDistrict}
            style={{ width: "100%" }}
            allowClear
            value={this.props.district}
          >
            {this.state.districts.map((e, index) => (
              <Select.Option key={index} value={e}>
                {e}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={3}>
          {/* <label style={{ color: "transparent" }}>-</label> */}
          <Button
            disabled={
              !this.props.category && !this.props.region && !this.props.district
            }
            block
            type="primary"
            onClick={this.props.resetFilter}
          >
            <img src="./icons/reset.svg" alt="navigation" width="15px" />{" "}
            <span style={{ marginLeft: "10px" }}>Сбросить</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

export default Filter;
