import React, { Component } from "react";
import moment from "moment";
import cubejs from "../../cube";
import { Calendar, Badge } from "antd";
import Lists from "./Lists";
import CardsSummary from "./CardsSummary";
import { MainWrapper } from "../../components/newDesign/MainWrapper";
import { FilterWrapper } from "../../components/newDesign/FilterWrapper";
import { FilterItem } from "../../components/newDesign/FilterItem";
import { RowWrapper } from "../../components/newDesign/RowWrapper";
import { ChartCard } from "../../components/newDesign/ChartCard";
import { DatePickerR } from "../../components/newDesign/DatePicker/DatePickerR";
import { TabMainTitle } from "../../components/newDesign/TabMainTitle";

const cubejsApi = cubejs({ appId: 1 });

export default class CalendarCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment(),
      listResult: [],
      emergency: 0,
      police: 0,
      ambulance: 0,
      emergencyData: [],
      ambulanceData: [],
      policeData: [],
      range: [moment().subtract(30, "d"), moment()],
    };
  }

  onSelect = (value) => {
    this.state.result.forEach((item) => {
      if (
        moment(item[Object.keys(item)[0]]).format("DD MM YYYY") ===
        value.format("DD MM YYYY")
      ) {
        this.setState({
          ambulance: item["AmbulanceView.count"],
          emergency: item["EmergencyDepartmentView.count"],
          police: item["PoliceView.count"],
        });
      }
    });
  };

  getListData = (value) => {
    let listData;
    let { result } = this.state;
    if (result) {
      result.forEach((item) => {
        if (
          moment(item[Object.keys(item)[0]]).format("DD MM YYYY") ===
          value.format("DD MM YYYY")
        ) {
          listData = [
            item["EmergencyDepartmentView.count"]
              ? {
                  type: "warning",
                  content: item["EmergencyDepartmentView.count"],
                }
              : "",
            item["AmbulanceView.count"]
              ? {
                  type: "success",
                  content: item["AmbulanceView.count"],
                }
              : "",
            item["PoliceView.count"]
              ? {
                  type: "error",
                  content: item["PoliceView.count"],
                }
              : "",
          ];
        }
      });
    }
    return listData || [];
  };

  dateCellRender = (value) => {
    const listData = this.getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item["type"]}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  getAllData = () => {
    const { range } = this.state;

    const DepartmentLists = [
      {
        dimensions: [
          "EmergencyDepartmentView.category",
          "EmergencyDepartmentView.comment",
          "EmergencyDepartmentView.subCategory",
          "EmergencyDepartmentView.dateTime",
        ],
        order: {
          "EmergencyDepartmentView.dateTime": "desc",
        },
        filters: [
          {
            member: "EmergencyDepartmentView.dateTime",
            operator: "inDateRange",
            values: range,
          },
        ],
        renewQuery: true,
      },
      {
        dimensions: [
          "PoliceView.dateTime",
          "PoliceView.category",
          "PoliceView.comment",
        ],
        order: {
          "PoliceView.dateTime": "desc",
        },
        filters: [
          {
            member: "PoliceView.dateTime",
            operator: "inDateRange",
            values: range,
          },
        ],
        renewQuery: true,
      },
      {
        dimensions: [
          "AmbulanceView.comment",
          "AmbulanceView.category",
          "AmbulanceView.subcategory",
          "AmbulanceView.dateTime",
        ],
        filters: [
          {
            member: "AmbulanceView.dateTime",
            operator: "inDateRange",
            values: range,
          },
        ],
        renewQuery: true,
        order: {
          "AmbulanceView.dateTime": "desc",
        },
      },
    ];
    let req = [
      {
        measures: ["EmergencyDepartmentView.count"],
        timeDimensions: [
          {
            dimension: "EmergencyDepartmentView.dateTime",
            granularity: "day",
          },
        ],
        filters: [
          {
            member: "EmergencyDepartmentView.dateTime",
            operator: "inDateRange",
            values: range,
          },
        ],
        renewQuery: true,
      },
      {
        measures: ["AmbulanceView.count"],
        timeDimensions: [
          {
            dimension: "AmbulanceView.dateTime",
            granularity: "day",
          },
        ],
        filters: [
          {
            member: "AmbulanceView.dateTime",
            operator: "inDateRange",
            values: range,
          },
        ],
        renewQuery: true,
      },
      {
        measures: ["PoliceView.count"],
        timeDimensions: [
          {
            dimension: "PoliceView.dateTime",
            granularity: "day",
          },
        ],
        filters: [
          {
            member: "PoliceView.dateTime",
            operator: "inDateRange",
            values: range,
          },
        ],
        renewQuery: true,
      },
    ];

    Promise.all(req.map((e) => cubejsApi.load(e))).then((res) => {
      let temp = res.map((e) => e.rawData());

      let data = temp[0].concat(temp[1], temp[2]);

      let obj = {};

      for (let i = 0; i < data.length; i++) {
        let date = data[i][Object.keys(data[i])[0]];
        let p_date = obj[date] || {};
        obj[date] = Object.assign(p_date, data[i]);
      }

      let result = Object.values(obj);

      this.setState({ data, result });
    });

    Promise.all(DepartmentLists.map((e) => cubejsApi.load(e))).then((res) => {
      let temp = res.map((e) => e.rawData());

      this.setState({
        emergencyData: temp[0],
        ambulanceData: temp[2],
        policeData: temp[1],
        listResult: temp,
      });
    });
  };

  async componentDidMount() {
    this.getAllData();
  }

  render() {
    let { emergencyData, ambulanceData, policeData } = this.state;
    return (
      <MainWrapper>
        <TabMainTitle>
          Информация за сегодня{" "}
          <span className="date-period">{moment().format("DD.MM.YYYY")}</span>
        </TabMainTitle>
        <FilterWrapper>
          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={(range) => {
                this.setState({ range }, this.getAllData);
              }}
              value={this.state.range}
              size="small"
              separator="—"
            />
          </FilterItem>
        </FilterWrapper>

        <RowWrapper>
          <ChartCard title="Календарь" style={{ flex: 2 }}>
            <Calendar
              onSelect={this.onSelect}
              dateCellRender={this.dateCellRender}
              monthCellRender={this.monthCellRender}
              className="custom-calendar"
            />
          </ChartCard>
          <ChartCard title="112-ДЧС" style={{ color: "#fff" }}>
            <Lists
              code="EmergencyDepartmentView"
              dataSource={emergencyData ? emergencyData : []}
              title="112 - ДЧС"
            />
          </ChartCard>
          <ChartCard title="103-Скорая помощь">
            <Lists
              code="AmbulanceView"
              dataSource={ambulanceData ? ambulanceData : []}
              title="103 - Скорая помощь"
            />
          </ChartCard>
          <ChartCard title="102-Департамент полиции">
            <Lists
              code="PoliceView"
              dataSource={policeData ? policeData : []}
              title="102 - Департамент полиции"
            />
          </ChartCard>
        </RowWrapper>
        <CardsSummary
          ambulance={this.state.ambulance}
          police={this.state.police}
          emergency={this.state.emergency}
        />
      </MainWrapper>
    );
  }
}
