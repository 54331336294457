import React, { Component } from "react";
import { Row, Col, DatePicker, Select, Radio } from "antd";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";
import cubejs from "../../cube";
import { VerticalBarChart, PieChart } from "../../components/visualizations";
import { generatePieData, generateBarData } from "../../utils/visualizations";
import {
  dateGroups,
  DAY_IN_MONTH,
  DAY_IN_WEEK,
  HOUR_IN_DAY,
  WEEK_DAYS,
} from "../../constants";
import { cloneDeep, groupBy, reduce } from "lodash";
import ExportExcel from "../../components/ExportExcel";
const cubejsApi = cubejs({ appId: 1 });
const { Option } = Select;

class CategoryAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersData: [
        {
          key: "Appeals.category",
          title: "Категория",
          options: [],
          placeholder: "Выберите категорию",
        },
        {
          key: "Appeals.subCategory",
          title: "Подкатегория",
          options: [],
          placeholder: "Выберите подкатегорию",
        },
      ],
      range: [
        moment().startOf("month").utc(6).startOf("day"),
        moment().utc(6).endOf("day"),
      ],
      pieLegends: [],
      averageAppeals: 0,
      granularity: "day",
      radioValue: "Appeals.category",
      categories: [],
      subCategories: [],
      pieTitle: "",
      tempGroup: null,
      tempGroupLoading: false,
      filtersDataLocality: {
        "Appeals.region": {
          key: "Appeals.region",
          title: "Регион",
          options: [],
          values: [],
        },
        "Appeals.district": {
          key: "Appeals.district",
          title: "Населенный пункт",
          options: [],
          values: [],
        },
      },
    };
  }

  async componentDidMount() {
    this.loadFilters();
  }

  async loadFilters() {
    let { filtersDataLocality, filtersData, range } = this.state;
    const filterNames = Object.keys(filtersDataLocality);
    let filters = [
      {
        member: "Appeals.submissionDate",
        operator: "inDateRange",
        values: range,
      },
    ];
    filterNames.forEach((f) => {
      const filter = filtersDataLocality[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });
    const reqs = filtersData.map((f) =>
      cubejsApi.load({ dimensions: [f.key], filters })
    );
    const responses = await Promise.all(reqs);
    responses.forEach((res, i) => {
      filtersData[i].options = res.rawData().map((e) => e[filtersData[i].key]);
    });
    const filtersReq = filterNames.map((f) =>
      cubejsApi.load({ dimensions: [f] })
    );
    const filterResponses = await Promise.all(filtersReq);

    filterResponses.forEach((res, i) => {
      filtersDataLocality = {
        ...filtersDataLocality,
        [filterNames[i]]: {
          ...filtersDataLocality[filterNames[i]],
          options: res
            .rawData()
            .map((d) => d[filterNames[i]])
            .filter((e) => e),
        },
      };
    });
    this.setState({ filtersDataLocality, filtersData });
  }

  applyFilters = (filter, values) => {
    let { filtersDataLocality } = this.state;
    this.setState(
      {
        filtersDataLocality: {
          ...filtersDataLocality,
          [filter]: {
            ...filtersDataLocality[filter],
            values,
          },
        },
      },
      () => this.loadFilters()
    );
  };

  changeRange = (value) => {
    if (value === DAY_IN_MONTH || value === DAY_IN_WEEK)
      this.setState({
        granularity: "day",
        tempGroup: value,
        tempGroupLoading: true,
      });
    else if (value === HOUR_IN_DAY)
      this.setState({
        granularity: "hour",
        tempGroup: value,
        tempGroupLoading: true,
      });
    else
      this.setState({
        granularity: value,
        tempGroup: null,
        tempGroupLoading: true,
      });

    setTimeout(() => {
      this.setState({
        tempGroupLoading: false,
      });
    }, 1000);
  };

  handlePieClick = (val, type, filters) => {
    if (val === "Другие") {
      let query = {
        measures: ["Appeals.appealsNum"],
        filters,
        renewQuery: true,
      };
      query.dimensions = [type];
      this.setPieData(query, type, "other");
    } else {
      const dim =
        "Appeals.subCategory" === type
          ? "Appeals.category"
          : "Appeals.subCategory";
      let query = {
        dimensions: [dim],
        measures: ["Appeals.appealsNum"],
        filters: [
          ...filters,
          {
            dimension: type,
            operator: "contains",
            values: [val],
          },
        ],
        renewQuery: true,
      };
      this.setPieData(query, dim);
    }
  };

  setPieData = (query, type, other) => {
    cubejsApi.load(query).then((r) => {
      let data = r
        .rawData()
        .map((e) => ({ title: e[type], value: +e["Appeals.appealsNum"] }));
      let total = data.reduce((a, b) => a + b.value, 0);
      data = data.map((e) => ({
        ...e,
        percent: ((e.value * 100) / total).toFixed(2),
      }));
      if (other) {
        data = data.filter((e) => +e.percent < 1);
        total = data.reduce((a, b) => a + b.value, 0);
        data = data.map((e) => ({
          ...e,
          percent: ((e.value * 100) / total).toFixed(2),
        }));
        this.setState({ pieLegends: data });
      } else this.setState({ pieLegends: data });
    });
  };

  radioChange = (evt) => {
    this.setState({
      radioValue: evt.target.value,
    });
  };

  changeFilters = (val, key) => {
    if (key === "Appeals.category") {
      this.setState({ categories: val });
    } else {
      this.setState({ subCategories: val });
    }
  };

  calcPercent = (data) => {
    if (data) {
      let summary = 0;
      data.forEach((d) => {
        summary = summary + parseFloat(d["Appeals.appealsNum"]);
      });
      return data.map((i) => ({
        ...i,
        percent: ((i["Appeals.appealsNum"] * 100) / summary).toFixed(2),
      }));
    }
  };

  getGroupedTemp = (barData = {}) => {
    let { tempGroup } = this.state;
    let newData = [],
      { data = [], ...others } = barData;
    if (tempGroup) {
      const groupedDays = groupBy(data, (el) => {
        if (tempGroup === DAY_IN_MONTH)
          return moment(el["Appeals.submissionDate"], "YYYY/MM/DD").format("D");
        else if (tempGroup === DAY_IN_WEEK)
          return moment(
            el["Appeals.submissionDate"],
            "YYYY/MM/DD"
          ).isoWeekday();
        else if (tempGroup === HOUR_IN_DAY)
          return parseInt(moment(el["Appeals.submissionDate"]).format("HH"));
      });
      Object.keys(groupedDays).forEach((key) => {
        newData.push({
          "Appeals.submissionDate":
            tempGroup === DAY_IN_WEEK
              ? WEEK_DAYS[key - 1]
              : tempGroup === HOUR_IN_DAY
              ? key + ":00"
              : key,
          "Appeals.appealsNum": reduce(
            groupedDays[key],
            function (sum, n) {
              return sum + parseFloat(n["Appeals.appealsNum"]);
            },
            0
          ),
        });
      });
      return { data: newData, ...others };
    } else return { data, ...others };
  };

  render() {
    const {
      range,
      pieLegends,
      granularity,
      filtersData,
      radioValue,
      categories,
      subCategories,
      pieTitle,
      filtersDataLocality,
      tempGroup,
      tempGroupLoading,
    } = this.state;
    const filterNames = Object.keys(filtersDataLocality);
    let filters = [
      {
        member: "Appeals.submissionDate",
        operator: "inDateRange",
        values: range,
      },
    ];
    filterNames.forEach((f) => {
      const filter = filtersDataLocality[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });
    return (
      <div className="p-10 category-analytics">
        <Row>
          <h4>Период</h4>
          <Col span={6}>
            <DatePicker.RangePicker
              onChange={(range) => {
                this.setState({ range }, this.getAverageAppeals);
              }}
              showTime={{
                secondStep: 60,
                minuteStep: 15,
              }}
              value={range}
              size="small"
              separator="—"
              style={{ marginBottom: 12 }}
            />
          </Col>
          {filterNames.map((f, i) => {
            const filter = filtersDataLocality[f];
            return (
              <Col span={6}>
                <Select
                  mode="multiple"
                  placeholder={`${filter.title}`}
                  onChange={(value) => this.applyFilters(filter.key, value)}
                  size="small"
                  allowClear={false}
                  style={{ width: "97%" }}
                >
                  {filter.options.map((val) => (
                    <Select.Option value={val} key={val}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            );
          })}
        </Row>
        <Row style={{ position: "relative" }}>
          <Col span={12}>
            <h2 align="center">Категории</h2>
            <QueryRenderer
              query={{
                measures: ["Appeals.appealsNum"],
                dimensions: ["Appeals.category"],
                filters,
                renewQuery: true,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <>
                    <PieChart
                      loading={!resultSet}
                      hideSmallValues
                      id="piechartid1"
                      disableMovement
                      showTotal
                      {...cloneDeep(generatePieData(resultSet))}
                      handleClick={(evt) =>
                        this.handlePieClick(evt, "Appeals.category", filters)
                      }
                    />
                    <ExportExcel
                      filename="Категории"
                      data={this.calcPercent(generatePieData(resultSet)?.data)}
                      style={{ marginRight: "10px", marginBottom: "10px" }}
                      fields={[
                        { title: "Категория", dataIndex: "Appeals.category" },
                        {
                          title: "Количество",
                          dataIndex: "Appeals.appealsNum",
                        },
                        {
                          title: "Процент",
                          dataIndex: "percent",
                        },
                      ]}
                    />
                  </>
                );
              }}
            />
          </Col>
          <Col span={12}>
            {pieLegends[0] && (
              <div className="custom-legend">
                <h4>{pieTitle}</h4>
                <ul>
                  {pieLegends.map((e, key) => (
                    <li key={key}>{`${key + 1}.${e.title}: ${e.value} - ${
                      e.percent
                    }%`}</li>
                  ))}
                </ul>
              </div>
            )}

            <h2 align="center">Подкатегории</h2>
            <QueryRenderer
              query={{
                measures: ["Appeals.appealsNum"],
                dimensions: ["Appeals.subCategory"],
                filters,
                renewQuery: true,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <>
                    <PieChart
                      loading={!resultSet}
                      id="piechartid2"
                      showTotal
                      hideSmallValues
                      disableMovement
                      handleClick={(evt) =>
                        this.handlePieClick(evt, "Appeals.subCategory", filters)
                      }
                      {...cloneDeep(generatePieData(resultSet))}
                    />
                    <ExportExcel
                      filename="Подкатегории"
                      data={this.calcPercent(generatePieData(resultSet)?.data)}
                      style={{ marginRight: "10px", marginBottom: "10px" }}
                      fields={[
                        {
                          title: "Подкатегория",
                          dataIndex: "Appeals.subCategory",
                        },
                        {
                          title: "Количество",
                          dataIndex: "Appeals.appealsNum",
                        },
                        {
                          title: "Процент",
                          dataIndex: "percent",
                        },
                      ]}
                    />
                  </>
                );
              }}
            />
          </Col>
        </Row>
        <h2 style={{ marginTop: "30px", textAlign: "center" }}>
          Количество обращений в разрезе периода
        </h2>
        <Row gutter={20} style={{ marginTop: "20px" }}>
          <Col span={4}>
            <h4 style={{ color: "transparent" }}>Radio</h4>
            <Radio.Group
              size="small"
              value={radioValue}
              buttonStyle="solid"
              style={{ marginBottom: "10px" }}
              onChange={this.radioChange}
            >
              <Radio.Button value="Appeals.category">Категория</Radio.Button>
              <Radio.Button value="Appeals.subCategory">
                Подкатегория
              </Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={4}>
            <h4>Шаблон</h4>
            <Select
              defaultValue={granularity}
              onChange={(value) => this.changeRange(value)}
              size="small"
              style={{ width: "100%" }}
            >
              {dateGroups.map((e, key) => (
                <Option value={e.granularity} key={key}>
                  {e.label}
                </Option>
              ))}
            </Select>
          </Col>
          {filtersData.map((el) => {
            return (
              el.key === radioValue && (
                <Col span={6} key={el.key}>
                  <h4>{el.title}</h4>
                  <Select
                    onChange={(val) => this.changeFilters(val, el.key)}
                    size="small"
                    mode="multiple"
                    value={
                      el.key === "Appeals.category" ? categories : subCategories
                    }
                    style={{ width: "100%" }}
                    placeholder={el.placeholder}
                  >
                    <Option value="Все">Все</Option>
                    {el.options.map((e, key) => (
                      <Option value={e} key={key}>
                        {e}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )
            );
          })}
        </Row>
        <Row style={{ marginTop: "10px" }}>
          {radioValue === "Appeals.category" &&
            categories.map((cat) => (
              <Col span={12} key={cat}>
                <h3 align="center">{cat}</h3>
                <QueryRenderer
                  query={{
                    measures: ["Appeals.appealsNum"],
                    timeDimensions: [
                      {
                        dimension: "Appeals.submissionDate",
                        granularity: granularity,
                      },
                    ],
                    filters: [
                      ...filters,
                      {
                        dimension: "Appeals.category",
                        operator: cat !== "Все" ? "equals" : "notEquals",
                        values: [cat],
                      },
                    ],
                    renewQuery: true,
                  }}
                  cubejsApi={cubejsApi}
                  render={({ resultSet, loadingState }) => {
                    return (
                      <VerticalBarChart
                        loading={loadingState?.isLoading || tempGroupLoading}
                        showValues
                        id={`VerticalBarChart${cat}`}
                        dateAxis={!tempGroup}
                        height="450px"
                        {...this.getGroupedTemp(
                          cloneDeep(generateBarData(resultSet))
                        )}
                        values={[
                          {
                            title: "Количество обращений",
                            key: "Appeals.appealsNum",
                          },
                        ]}
                        rotate
                      />
                    );
                  }}
                />
              </Col>
            ))}
          {radioValue === "Appeals.subCategory" &&
            subCategories.map((cat) => (
              <Col span={12} key={cat}>
                <h3 align="center">{cat}</h3>
                <QueryRenderer
                  query={{
                    measures: ["Appeals.appealsNum"],
                    timeDimensions: [
                      {
                        dimension: "Appeals.submissionDate",
                        granularity: granularity,
                      },
                    ],
                    filters: [
                      ...filters,
                      {
                        dimension: "Appeals.subCategory",
                        operator: cat !== "Все" ? "equals" : "notEquals",
                        values: [cat],
                      },
                    ],
                    renewQuery: true,
                  }}
                  cubejsApi={cubejsApi}
                  render={({ resultSet, loadingState }) => {
                    return (
                      <VerticalBarChart
                        loading={loadingState?.isLoading || tempGroupLoading}
                        showValues
                        id={`VerticalBarChart${cat}`}
                        dateAxis={!tempGroup}
                        height="450px"
                        {...this.getGroupedTemp(
                          cloneDeep(generateBarData(resultSet))
                        )}
                        values={[
                          {
                            title: "Количество обращений",
                            key: "Appeals.appealsNum",
                          },
                        ]}
                        rotate
                      />
                    );
                  }}
                />
              </Col>
            ))}
        </Row>
      </div>
    );
  }
}

export default CategoryAnalytics;
