import React, { Component } from "react";
import PropTypes from "prop-types";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";

const COLORS = [
  "#25abe0",
  "#2a73dc",
  "#0a9574",
  "#a60c0a",
  "#3c2786",
  "#a05ccf",
];

export default class LineChart extends Component {
  componentDidMount() {
    am4core.options.commercialLicense = true;
    const { loading, id } = this.props;

    // Create chart instance
    const chart = am4core.create(id, am4charts.XYChart);
    chart.language.locale = am4lang_ru_RU;

    this.chart = chart;
    if (loading) this.showIndicator();
    this.initChart();
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading } = this.props;
    if (loading && !prevProps.loading) {
      this.showIndicator();
    } else if (!loading && prevProps.loading) {
      this.initChart();
    }
  }

  initChart = () => {
    const {
      data,
      category,
      categoryText,
      values,
      valueText,
      dateAxis,
      dotted,
      filled,
      rotate,
      showScrollbar,
      scrollbarWidth,
      scrollbarHeight,
      colorsArr,
    } = this.props;
    if (!data) return;

    let temp = [...data];

    if (this.props.sort) {
      this.props.date
        ? (temp = data.sort(
            (a, b) =>
              new Date(a["HeatmapView.dateEvent"]) -
              new Date(b["HeatmapView.dateEvent"])
          ))
        : (temp = data.sort(
            (a, b) => a["HeatmapView.hours"] - b["HeatmapView.hours"]
          ));
    }

    // Add data
    this.chart.data = data.length ? temp : [];

    if (values.length > 1) {
      // legend
      this.chart.legend = new am4charts.Legend();
      this.chart.legend.useDefaultMarker = true;
      this.chart.legend.fontSize = 11;
      this.chart.legend.position = "bottom";
      this.chart.legend.paddingTop = 24;
      this.chart.legend.labels.template.text = "{name}[/]";
      this.chart.legend.itemContainers.template.paddingTop = 1;
      this.chart.legend.itemContainers.template.paddingBottom = 1;
      // truncate
      this.chart.legend.itemContainers.template.maxWidth = 250;
      this.chart.legend.itemContainers.template.truncate = true;
      this.chart.legend.itemContainers.template.paddingLeft = 12;
      this.chart.legend.layout = "grid";
      this.chart.legend.maxColumns = 4;

      let markerTemplate = this.chart.legend.markers.template;
      markerTemplate.width = 11;
      markerTemplate.height = 11;
      let marker = this.chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
    }

    // colors
    const colors = values.length
      ? values[0].color
        ? values.map((value) => value.color)
        : colorsArr
      : [];
    this.chart.colors.list = colors.map((color) => am4core.color(color));

    // Create axes
    let dateAx;
    this.chart.xAxes.clear();
    if (!dateAxis) {
      const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = category;
      if (categoryText) categoryAxis.title.text = categoryText;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 10;

      const label = categoryAxis.renderer.labels.template;
      label.fontSize = 12;
      label.lineHeight = 12;
      label.truncate = true;
      label.maxWidth = 60;
      label.tooltipText = "{category}";

      if (rotate) {
        label.horizontalCenter = "right";
        label.verticalCenter = "middle";
        label.rotation = 310;
      }
      categoryAxis.renderer.grid.template.disabled = true;
    } else {
      dateAx = this.chart.xAxes.push(new am4charts.DateAxis());
      dateAx.dataFields.category = category;
      if (categoryText) dateAx.title.text = categoryText;
      dateAx.renderer.grid.template.location = 50;
      dateAx.renderer.minGridDistance = 30;
      dateAx.start = 0; //0.89;
      dateAx.keepSelection = true;
      const label = dateAx.renderer.labels.template;
      label.fontSize = 12;
      label.lineHeight = 12;
      if (rotate) {
        label.horizontalCenter = "right";
        label.verticalCenter = "middle";
        label.rotation = 310;
      }
      // dateAx.renderer.grid.template.disabled = true;

      // dateAx.parseDates = false;

      // Set input format for the dates
      this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd"; //inputDateFormat;
      // chart.dataDateFormat = "YYYY-MM-DDTJJ:NN:SS";
      // chart.dateFormatter.dateFormat = "dd";
      // dateAx.dateFormatter = new am4core.DateFormatter();
      // dateAx.dateFormatter.dateFormat = "dd";
      // dateAx.dateFormats.setKey("day", dateFormat);
      // dateAx.periodChangeDateFormats.setKey("day", dateFormat);
      // dateAx.dateFormats.setKey("month", "[bold]yyyy[/]");
      // dateAx.periodChangeDateFormats.setKey("month", "[bold]yyyy[/]");
    }

    this.chart.yAxes.clear();
    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    if (valueText) valueAxis.title.text = valueText;
    valueAxis.renderer.labels.template.fontSize = 12;

    // Create series
    this.chart.series.clear();
    // ScrollBarX initials
    if (showScrollbar) {
      this.chart.scrollbarX = new am4core.Scrollbar();
      this.chart.scrollbarX.parent = this.chart.bottomAxesContainer;
      if (scrollbarWidth)
        this.chart.scrollbarX.width = new am4core.Percent(scrollbarWidth);
      if (scrollbarHeight) this.chart.scrollbarX.minHeight = scrollbarHeight;
      this.chart.scrollbarX.align = "center";
      // if (startScrollbar) this.chart.scrollbarX.start = startScrollbar;
      // if (endScrollbar) this.chart.scrollbarX.end = endScrollbar;
    }

    values.forEach((value, i) => {
      const { key, title } = value;
      let series = this.chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = key;
      series.dataFields[dateAxis ? "dateX" : "categoryX"] = category;
      series.name = title;
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      if (filled) series.fillOpacity = 0.4;
      series.strokeWidth = 2;
      series.minBulletDistance = 15;
      // Push every series to ScrollBarX
      // this.chart.scrollbarX.series.push(series);

      // Make bullets grow on hover
      if (dotted) {
        const bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 0;
        bullet.circle.radius = 4;
        // bullet.circle.fill = am4core.color("#fff");
      }

      // colors
      // series.columns.template.stroke = am4core.color(
      //   value.color ? value.color : COLORS[i]
      // );
      // series.columns.template.fill = am4core.color(
      //   value.color ? value.color : COLORS[i]
      // );
    });
    // Hide labels in ScrollBarX
    // this.chart.scrollbarX.scrollbarChart.xAxes.getIndex(
    //   0
    // ).renderer.labels.template.disabled = true;
    // Params in ScrollBarX

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();
    // this.chart.scrollbarX = new am4charts.XYChartScrollbar();
    // chart.scrollbarX.series.push(series);
    // this.chart.scrollbarX.parent = this.chart.bottomAxesContainer;

    this.chart.events.on("ready", (ev) => {
      // if (dateAxis) {
      //
      //   dateAx.zoom({ start: 0.79, end: 1 });
      // }
      // if (this.loading)
      this.hideIndicator();
    });
    this.chart.events.dispatch("ready");
  };

  showIndicator = () => {
    this.indicator = this.chart.tooltipContainer.createChild(am4core.Container);
    this.indicator.background.fill = am4core.color("#323c48");
    this.indicator.background.fillOpacity = 0.8;
    this.indicator.width = am4core.percent(100);
    this.indicator.height = am4core.percent(100);

    const indicatorLabel = this.indicator.createChild(am4core.Label);
    indicatorLabel.text = "Loading data...";
    indicatorLabel.align = "center";
    indicatorLabel.valign = "middle";
    indicatorLabel.fontSize = 16;
    indicatorLabel.color = am4core.color("#9a9a9a");
    indicatorLabel.dy = 15;

    const spinner = this.indicator.createChild(am4core.Image);
    spinner.href = "/images/spinner.gif";
    spinner.align = "center";
    spinner.valign = "middle";
    spinner.horizontalCenter = "middle";
    spinner.verticalCenter = "middle";
    spinner.width = 24;
    spinner.height = 24;
    spinner.dy = -15;
  };

  hideIndicator = () => {
    this.indicator.hide();
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { id, height } = this.props;
    return <div id={id} style={{ width: "100%", height }} />;
  }
}

LineChart.defaultProps = {
  loading: true,
  id: "line-chart",
  category: "category",
  dateAxis: false,
  inputDateFormat: "yyyy-MM-ddTHH:mm:ss.000",
  dateFormat: "dd MMM",
  dotted: false,
  filled: false,
  rotate: false,
  showScrollbar: true,
  height: "300px",
  startScrollbar: 0,
  colorsArr: COLORS,
  endScrollbar: 1,
};

LineChart.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  category: PropTypes.string,
  categoryText: PropTypes.string,
  values: PropTypes.array.isRequired,
  valueText: PropTypes.string,
  dateAxis: PropTypes.bool,
  inputDateFormat: PropTypes.string,
  dateFormat: PropTypes.string,
  dotted: PropTypes.bool,
  filled: PropTypes.bool,
  rotate: PropTypes.bool,
  showScrollbar: PropTypes.bool,
  height: PropTypes.string,
  startScrollbar: PropTypes.number,
  colorsArr: PropTypes.array,
  endScrollbar: PropTypes.number,
};
