import React from "react";
import { Checkbox } from "antd";
import styles from "./CheckboxGroup.module.scss";

export default function CheckboxGroup({ list = [], ...componentProps }) {
  return (
    <div className={styles.checkbox_group_wrapper}>
      <Checkbox.Group className={styles.checkbox_group} {...componentProps}>
        {list.map((item) => (
          <Checkbox className={styles.checkbox_group_button} value={item}>
            {item}
          </Checkbox>
        ))}
      </Checkbox.Group>
    </div>
  );
}
