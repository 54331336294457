import React, { Component } from "react";
import { Modal } from "antd";
import cubejs from "../../cube";
import YandexHeatMapWrap from "../../charts/YandexHeatMapWrap";
import moment from "moment";
import LoadingCss from "../../common/LoadingCss";
import YandexMapWrap from "../../charts/YandexMapWrap";
import { MainWrapper } from "../../components/newDesign/MainWrapper";
import { FilterWrapper } from "../../components/newDesign/FilterWrapper";
import { FilterItem } from "../../components/newDesign/FilterItem";
import { SelectList } from "../../components/newDesign/SelectList";
import { DatePickerR } from "../../components/newDesign/DatePicker/DatePickerR";
import { RadioGroup } from "../../components/newDesign/RadioGroup/RadioGroup";
import { ChartCard } from "../../components/newDesign/ChartCard";
import { coordinatesFilter, STATUS_BY_COLOR } from "./utils";
import Details from "./Details";
import MapLegend from "./MapLegend";
import uniq from "lodash/uniq";
const cubejsApi = cubejs({ appId: 1 });

export default class AppealsMap extends Component {
  state = {
    range: [moment().startOf("month").utc(6).startOf("day"),
    moment().utc(6).endOf("day")],
    data: [],
    mapType: "a",
    categories: [],
    subCategories: [],
    regions: [],
    districts: [],
    statuses: [],
    selectedRegions: [],
    selectedDistricts: [],
    selectedCategories: [],
    selectedSubCategories: [],
    selectedStatuses: [],
    loading: true,
    modal: {
      show: false,
      data: [],
    },
    disabled: false,
  };

  async componentDidMount() {
    this.loadDictionaries();
    this.getData();
  }

  async loadDictionaries() {
    const { range } = this.state;
    const regions = await cubejsApi.load({
      dimensions: [
        "AppealsRegion.nameRu",
        "AppealsRegion.parentId",
        "AppealsRegion.id",
      ],
      filters: [
        {
          member: "AppealsRegion.parentId",
          operator: "notSet",
        },
      ],
    });

    const districts = await cubejsApi.load({
      dimensions: [
        "AppealsRegion.nameRu",
        "AppealsRegion.parentId",
        "AppealsRegion.id",
      ],
      filters: [
        {
          member: "AppealsRegion.parentId",
          operator: "set",
        },
      ],
    });
    const categories = await cubejsApi.load({
      dimensions: ["Appeals.category", "Appeals.region", "Appeals.district"],
      filters: [
        {
          member: "Appeals.submissionDate",
          operator: "inDateRange",
          values: range,
        },
        ...coordinatesFilter,
      ],
    });
    const subCategories = await cubejsApi.load({
      dimensions: ["Appeals.subCategory", "Appeals.region", "Appeals.district"],
      filters: [
        {
          member: "Appeals.submissionDate",
          operator: "inDateRange",
          values: range,
        },
        ...coordinatesFilter,
      ],
    });
    const statuses = await cubejsApi.load({
      dimensions: ["Appeals.theStatus"],
      filters: [
        {
          member: "Appeals.submissionDate",
          operator: "inDateRange",
          values: range,
        },
        ...coordinatesFilter,
      ],
    });

    this.setState({
      regions: regions.rawData(),
      districts: districts.rawData(),
      categories: categories.rawData(),
      subCategories: subCategories.rawData(),
      statuses: statuses.rawData(),
    });
  }

  calculateReplyDays = (replyDate, plannedDate, expired) => {
    if (expired === "Просрочено") {
      if (replyDate === null) {
        return moment().diff(moment(plannedDate), "days");
      } else {
        return moment(replyDate).diff(moment(plannedDate), "days");
      }
    } else if (expired === "Не просрочено") {
      return 0;
    }
  };

  getData = () => {
    const {
      selectedRegions,
      selectedDistricts,
      selectedCategories,
      selectedSubCategories,
      selectedStatuses,
    } = this.state;
    let filters = [];
    if (selectedRegions.length) {
      filters.push({
        member: "Appeals.region",
        operator: "equals",
        values: selectedRegions.map((f) => f.toString()),
      });
    }
    if (selectedDistricts.length) {
      filters.push({
        member: "Appeals.district",
        operator: "equals",
        values: selectedDistricts.map((f) => f.toString()),
      });
    }

    if (selectedCategories.length) {
      filters.push({
        member: "Appeals.category",
        operator: "equals",
        values: selectedCategories.map((f) => f.toString()),
      });
    }
    if (selectedSubCategories.length) {
      filters.push({
        member: "Appeals.subCategory",
        operator: "equals",
        values: selectedSubCategories.map((f) => f.toString()),
      });
    }
    if (selectedStatuses.length) {
      filters.push({
        member: "Appeals.theStatus",
        operator: "equals",
        values: selectedStatuses.map((f) => f.toString()),
      });
    }
    let query = {
      dimensions: [
        "Appeals.id",
        "Appeals.x",
        "Appeals.y",
        "Appeals.theStatus",
        "Appeals.plannedDate",
        "Appeals.replyDate",
        "Appeals.category",
        //new
        "Appeals.appealNum",
        "Appeals.source",
        "Appeals.appealType",
        "Appeals.subCategory",
        "Appeals.appealText",
        "Appeals.submissionDate",
        "Appeals.expired",
        "Appeals.theStatus",
        "Appeals.region",
        "Appeals.district",
        "Appeals.fio",
        "Appeals.phoneNumber",
        "Appeals.answer",
      ],
      measures: [
        "Appeals.appealsNum",
        "Appeals.finishedAppeals",
        "Appeals.expiredAppeals",
        "Appeals.registredAppeals",
        "Appeals.efficiencyCoef",
        "Appeals.expiredForOperatorAppeals",
        "Appeals.outOfTimeForOperator",
      ],
      filters: [
        {
          member: "Appeals.submissionDate",
          operator: "inDateRange",
          values: this.state.range,
        },
        ...filters,
      ],
    };
    this.setState({ loading: true });

    cubejsApi
      .load(query)
      .then((r) => {
        let data = r.rawData().filter((i) => i["Appeals.x"] && i["Appeals.y"]);
        this.setState({
          data: data.map((e) => ({
            ...e,
            "Appeals.x": parseFloat(e["Appeals.y"]),
            "Appeals.y": parseFloat(e["Appeals.x"]),
            "Appeals.replyDays": this.calculateReplyDays(
              e["Appeals.replyDate"],
              e["Appeals.plannedDate"],
              e["Appeals.expired"]
            ),
            "Appeals.submissionDate":
              e["Appeals.submissionDate"] != null
                ? moment(e["Appeals.submissionDate"]).format("YYYY-MM-DD")
                : "",
            "Appeals.submissionTime":
              e["Appeals.submissionDate"] != null
                ? moment(e["Appeals.submissionDate"]).format("HH:mm")
                : "",
            "Appeals.replyDate":
              e["Appeals.replyDate"] != null
                ? moment(e["Appeals.replyDate"]).format("YYYY-MM-DD")
                : "",
            "Appeals.plannedDate":
              e["Appeals.plannedDate"] != null
                ? moment(e["Appeals.plannedDate"]).format("YYYY-MM-DD")
                : "",
            color: this.getColor(e),
            statusDone: STATUS_BY_COLOR[this.getColor(e)],
          })),
        });
      })
      .catch((err) => {
        console.log("Appp err", err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  handleRange = (val) => {
    this.setState({ range: val }, () => {
      this.loadDictionaries();
      this.getData();
    });
  };

  getColor = (el) => {
    if (
      (el["Appeals.theStatus"] === "Предоставлен быстрый ответ" ||
        el["Appeals.theStatus"] === "Завершено") &&
      el["Appeals.plannedDate"] > el["Appeals.replyDate"]
    )
      return "green";
    else if (
      (el["Appeals.theStatus"] === "Зарегистрировано" ||
        el["Appeals.theStatus"] === "направлено в ИО" ||
        el["Appeals.theStatus"] === "получен ответ" ||
        el["Appeals.theStatus"] === "на доработке" ||
        el["Appeals.theStatus"] === "проверка оценки" ||
        el["Appeals.theStatus"] === "проверка ответа" ||
        el["Appeals.theStatus"] === "долгосрочный" ||
        el["Appeals.theStatus"] === "на завершении в ИО" ||
        el["Appeals.theStatus"] === "отклонена") &&
      el["Appeals.plannedDate"] > new Date().toISOString()
    )
      return "blue";
    else if (
      (!el["Appeals.replyDate"] &&
        el["Appeals.plannedDate"] < new Date().toISOString()) ||
      (el["Appeals.replyDate"] &&
        el["Appeals.plannedDate"] < el["Appeals.replyDate"])
    )
      return "red";
    return "blue";
  };

  applyFilters = (filter, values) => {
    const { regions, districts, selectedRegions } = this.state;
    if (filter === "selectedRegions" && !values.length) {
      this.setState({ selectedDistricts: [] });
    }
    if (filter === "selectedDistricts" && selectedRegions.length === 0) {
      const districtObject = districts.find(
        (district) => district["AppealsRegion.nameRu"] === values[0]
      );
      const regionObject = regions.find(
        (region) =>
          region["AppealsRegion.id"] ===
          districtObject["AppealsRegion.parentId"]
      );
      this.setState({
        selectedRegions: [regionObject["AppealsRegion.nameRu"]],
      });
    }
    this.setState(
      {
        [filter]: values,
      },
      () => this.getData()
    );
  };

  hideDetails = () => this.setState({ modal: { show: false, data: [] } });

  handleMapRegions = (item) => item["AppealsRegion.nameRu"];

  filteredOptions = (key) => {
    const {
      regions,
      districts,
      categories,
      subCategories,
      selectedRegions,
      selectedDistricts,
    } = this.state;
    if (key === "district") {
      return districts
        .filter((district) => {
          if (!selectedRegions.length) {
            return true;
          }
          const ids = [];
          selectedRegions.forEach((region) => {
            const regionObj = regions.find(
              (it) => region === it["AppealsRegion.nameRu"]
            );
            ids.push(regionObj["AppealsRegion.id"]);
          });
          return ids.includes(district["AppealsRegion.parentId"]);
        })
        .map(this.handleMapRegions);
    }
    if (key === "category" || "subCategory") {
      const options = key === "category" ? categories : subCategories;
      const newOptions = options
        .filter((item) => {
          if (!selectedRegions?.length) {
            return true;
          }
          if (!selectedDistricts?.length) {
            const names = [];
            selectedRegions.forEach((region) => {
              const regionObj = regions.find(
                (it) => region === it["AppealsRegion.nameRu"]
              );
              names.push(regionObj?.["AppealsRegion.nameRu"]);
            });
            return names.includes(item["Appeals.region"]);
          }
          const districtNames = [];
          const regionNames = [];
          selectedDistricts.forEach((district) => {
            const districtObj = districts.find(
              (it) => district === it["AppealsRegion.nameRu"]
            );
            districtNames.push(districtObj?.["AppealsRegion.nameRu"]);
          });
          selectedRegions.forEach((region) => {
            const regionObj = this.state.regions.find(
              (it) => region === it["AppealsRegion.nameRu"]
            );
            regionNames.push(regionObj?.["AppealsRegion.nameRu"]);
          });

          return (
            districtNames.includes(item["Appeals.district"]) &&
            regionNames.includes(item["Appeals.region"])
          );
        })
        .map(
          (item) =>
            item[
              key === "category" ? "Appeals.category" : "Appeals.subCategory"
            ]
        );
      return uniq(newOptions);
    }
  };

  render() {
    const {
      range,
      loading,
      modal,
      regions,
      statuses,
      selectedRegions,
      selectedDistricts,
      selectedCategories,
      selectedSubCategories,
      selectedStatuses,
    } = this.state;

    const mapHeight = window.innerHeight - 144 + "px";
    return (
      <MainWrapper>
        <FilterWrapper>
          <FilterItem>
            <SelectList
              label={"Регион:"}
              mode="multiple"
              onChange={(value) => this.applyFilters("selectedRegions", value)}
              value={selectedRegions}
              size="small"
              allowClear
              list={regions.map(this.handleMapRegions)}
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={"Населенный пункт:"}
              mode="multiple"
              onChange={(value) =>
                this.applyFilters("selectedDistricts", value)
              }
              value={selectedDistricts}
              size="small"
              allowClear
              list={this.filteredOptions("district")}
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={"Категория:"}
              mode="multiple"
              onChange={(value) =>
                this.applyFilters("selectedCategories", value)
              }
              value={selectedCategories}
              size="small"
              allowClear
              list={this.filteredOptions("category")}
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={"Подкатегория:"}
              mode="multiple"
              onChange={(value) =>
                this.applyFilters("selectedSubCategories", value)
              }
              value={selectedSubCategories}
              size="small"
              allowClear
              list={this.filteredOptions("subcategory")}
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={"Статус:"}
              mode="multiple"
              onChange={(value) => this.applyFilters("selectedStatuses", value)}
              value={selectedStatuses}
              size="small"
              allowClear
              list={statuses.map((it) => it["Appeals.theStatus"])}
            />
          </FilterItem>
          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={this.handleRange}
              value={this.state.range}
              size="small"
              separator="—"
            />
          </FilterItem>
        </FilterWrapper>
        <ChartCard>
          <RadioGroup
            defaultValue="a"
            onChange={(value) =>
              this.setState({
                mapType: value.target.value,
              })
            }
            list={[
              { key: "a", value: "Карта" },
              { key: "b", value: "Тепловая карта" },
            ]}
          />
          {loading ? (
            <LoadingCss />
          ) : (
            <>
              {this.state.mapType === "a" ? (
                <>
                  <YandexMapWrap
                    objects={this.state.data}
                    showDefaultCity
                    idDataKey="Appeals.id"
                    xDataKey="Appeals.x"
                    yDataKey="Appeals.y"
                    handleClick={(id) =>
                      this.setState({
                        modal: {
                          show: true,
                          data: this.state.data
                            .filter((i) => i["Appeals.id"] === id)
                            .map((i, index) => ({ ...i, number: index + 1 })),
                        },
                      })
                    }
                    onClusterClick={(d) =>
                      this.setState({
                        modal: {
                          show: true,
                          data: d.map((i, index) => ({
                            ...i,
                            number: index + 1,
                          })),
                        },
                      })
                    }
                    height={mapHeight}
                    zoom={5}
                  />
                  <MapLegend />
                </>
              ) : (
                <YandexHeatMapWrap
                  objects={this.state.data}
                  showDefaultCity
                  idDataKey="Appeals.id"
                  xDataKey="Appeals.x"
                  yDataKey="Appeals.y"
                  dateRange={range}
                  heatMap={true}
                  zoom={11}
                />
              )}
            </>
          )}
        </ChartCard>

        <Modal
          title=""
          visible={modal.show}
          onCancel={this.hideDetails}
          width="96%"
          footer={null}
          bodyStyle={{
            height: "88vh",
            overflowY: "auto",
          }}
          centered
        >
          <Details data={modal.data} />
        </Modal>
      </MainWrapper>
    );
  }
}
