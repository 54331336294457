import React, { Component, Fragment } from "react";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";
import { Table, Skeleton } from "antd";

import cubejs from "../cube";

import {
  SingleNumber,
  LineChart,
  HorizontalBarChart as HorizonBarChart,
  VerticalBarChart as VertBarChart,
  PieChart,
} from "../components/visualizations";
import YandexMapWrapDetail from "../charts/YandexMapWrapDetail";
import YandexHeatMapWrap from "../charts/YandexHeatMapWrap";
import ExportExcel from "../components/ExportExcel";
import {
  getSingleValue,
  generateLineData,
  generateBarData,
  generatePieData,
} from "../utils/visualizations";
import { formatDateTime } from "../utils/helpers";
import { rangeList } from "../constants";
import { TabMainTitle } from "../components/newDesign/TabMainTitle";
import { FilterWrapper } from "../components/newDesign/FilterWrapper";
import { FilterItem } from "../components/newDesign/FilterItem";
import { SelectList } from "../components/newDesign/SelectList";
import { DatePickerR } from "../components/newDesign/DatePicker/DatePickerR";
import { RowWrapper } from "../components/newDesign/RowWrapper";
import { ChartCard } from "../components/newDesign/ChartCard";
import { RadioGroup } from "../components/newDesign/RadioGroup/RadioGroup";
import { CHART_MAIN_COLOR, PIE_COLORS } from "../components/newDesign/utils";
import { MainWrapper } from "../components/newDesign/MainWrapper";

const cubejsApi = cubejs({ appId: 1 });

class Dtp extends Component {
  state = {
    filtersData: {
      "DtpKgf.fd1r06p2": {
        key: "DtpKgf.fd1r06p2",
        title: "Районы",
        options: [],
        values: [],
      },
      "DtpKgf.incision": {
        key: "DtpKgf.incision",
        title: "Населенный пункт",
        options: [],
        values: [],
      } /*,
      'DtpKgf.rtaType': {
        key: 'DtpKgf.rtaType',
        title: 'Тип ДТП',
        options: [],
        values: []
      }*/,
    },
    range: [
      moment().startOf("month").utc(6).startOf("day"),
      moment().utc(6).endOf("day"),
    ],
    mapType: "a",
  };

  changeRange = (value) =>
    this.setState({
      range: [moment(value), moment()],
    });

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({ filtersData });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  render() {
    const { filtersData, range, mapType } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [
      {
        member: "DtpKgf.rtaDate",
        operator: "inDateRange",
        values: range,
      },
    ];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });

    return (
      <MainWrapper>
        <TabMainTitle>
          Дорожно-транспортные происшествия на период c{" "}
          <span className="date-period">
            {range[0].format("DD.MM.YYYY")}{" "}
            <span style={{ color: "#fff" }}>по </span>{" "}
            {range[1].format("DD.MM.YYYY")}
          </span>
        </TabMainTitle>

        <FilterWrapper>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <FilterItem>
                <SelectList
                  label={filter.title + ":"}
                  mode="multiple"
                  onChange={(value) => this.applyFilters(filter.key, value)}
                  size="small"
                  allowClear
                  list={filter.options}
                />
              </FilterItem>
            );
          })}
          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={(range) => {
                this.setState({ range });
              }}
              value={range}
              size="small"
              separator="—"
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={"Шаблон:"}
              defaultValue={rangeList["Неделя"].toString()}
              onChange={(value) => this.changeRange(value)}
              size="small"
              showKey="title"
              valueKey="id"
              list={Object.entries(rangeList).map(([key, value]) => ({
                id: value.toString(),
                title: key,
              }))}
            />
          </FilterItem>
          <FilterItem action>
            <QueryRenderer
              query={{
                dimensions: [
                  "DtpKgf.id",
                  "DtpKgf.rtaType",
                  "DtpKgf.rtaDate",
                  "DtpKgf.fd1r06p2",
                  "DtpKgf.fd1r071p1",
                  "DtpKgf.fd1r07p2",
                  "DtpKgf.fd1r07p4",
                  "DtpKgf.fd1r09p1",
                  "DtpKgf.fd1r141p1",
                  "DtpKgf.fd1r14p1",
                  "DtpKgf.vehicleCategory",
                  "DtpKgf.isPublicTransport",
                ],
                filters,
                timeDimensions: [
                  {
                    dimension: "DtpKgf.rtaDate",
                    dateRange: range,
                    granularity: "month",
                  },
                ],
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return null;
                return (
                  <ExportExcel
                    filename="ДТП"
                    data={resultSet.rawData()}
                    fields={[
                      {
                        title: "Вид ДТП",
                        dataIndex: "DtpKgf.rtaType",
                      },
                      {
                        title: "Дата",
                        dataIndex: "DtpKgf.rtaDate",
                      },
                      {
                        title: "Район",
                        dataIndex: "DtpKgf.fd1r06p2",
                      },
                      {
                        title: "Погодные условия",
                        dataIndex: "DtpKgf.fd1r071p1",
                      },
                      {
                        title: "Время суток",
                        dataIndex: "DtpKgf.fd1r07p2",
                      },
                      {
                        title: "Место столкновения",
                        dataIndex: "DtpKgf.fd1r07p4",
                      },
                      {
                        title: "Нарушение",
                        dataIndex: "DtpKgf.fd1r09p1",
                      },
                      {
                        title: "Состояние водителя",
                        dataIndex: "DtpKgf.fd1r141p1",
                      },
                      {
                        title: "Виновник",
                        dataIndex: "DtpKgf.fd1r14p1",
                      },
                      {
                        title: "Категория прав",
                        dataIndex: "DtpKgf.vehicleCategory",
                      },
                      {
                        title: "Тип транспорта (общественный или частный)",
                        dataIndex: "DtpKgf.isPublicTransport",
                      },
                    ]}
                  />
                );
              }}
            />
          </FilterItem>
        </FilterWrapper>
        <RowWrapper>
          <ChartCard>
            <QueryRenderer
              query={{
                measures: ["DtpKgf.count"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <div style={{ padding: 16 }}>
                    <SingleNumber
                      number={+getSingleValue(resultSet, "DtpKgf.count")}
                      title="Количество проишествий:"
                    />
                  </div>
                );
              }}
            />
          </ChartCard>
        </RowWrapper>

        <RowWrapper>
          <ChartCard title="Общая динамика">
            <QueryRenderer
              query={{
                measures: ["DtpKgf.count"],
                dimensions: ["DtpKgf.rtaDate.day"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <LineChart
                    loading={!resultSet}
                    id="DtpKgfCategoryCommon"
                    {...generateLineData(resultSet)}
                    height="400px"
                    scrollbarWidth={75}
                    scrollbarHeight={4}
                    dateAxis
                    rotate
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard title="ДТП по районам">
            <QueryRenderer
              query={{
                measures: ["DtpKgf.count"],
                dimensions: ["DtpKgf.fd1r06p3"],
                filters,
                order: {
                  "DtpKgf.count": "asc",
                },
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <HorizonBarChart
                    loading={!resultSet}
                    id="DtpKgfDistrict"
                    {...generateBarData(resultSet)}
                    colorsArr={[CHART_MAIN_COLOR]}
                    showValues
                    wrapLabels
                    height="400px"
                    scrollStart={0.5}
                    scrollEnd={1}
                  />
                );
              }}
            />
          </ChartCard>
        </RowWrapper>

        <RowWrapper>
          <ChartCard title="ДТП по населенному пункту">
            <QueryRenderer
              query={{
                measures: ["DtpKgf.count"],
                dimensions: ["DtpKgf.incision"],
                filters,
                order: {
                  "DtpKgf.count": "asc",
                },
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <HorizonBarChart
                    loading={!resultSet}
                    id="DtpKgfIncision"
                    {...generateBarData(resultSet)}
                    colorsArr={[CHART_MAIN_COLOR]}
                    showValues
                    wrapLabels
                    scrollStart={0.7}
                    scrollEnd={1}
                    height="400px"
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard title="ДТП по нарушению">
            <QueryRenderer
              query={{
                measures: ["DtpKgf.count"],
                dimensions: ["DtpKgf.fd1r09p1"],
                filters,
                order: {
                  "DtpKgf.count": "asc",
                },
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <HorizonBarChart
                    loading={!resultSet}
                    id="DtpKgfViolationType"
                    {...generateBarData(resultSet)}
                    colorsArr={[CHART_MAIN_COLOR]}
                    showValues
                    wrapLabels
                    scrollStart={0.7}
                    scrollEnd={1}
                    height="400px"
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard title="Условия ДТП">
            <QueryRenderer
              query={{
                measures: ["DtpKgf.count"],
                dimensions: ["DtpKgf.fd1r071p1"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <VertBarChart
                    loading={!resultSet}
                    id="DtpKgfWeather"
                    {...generateBarData(resultSet)}
                    // rotate
                    showValues
                    height="400px"
                    // wrapLabels
                  />
                );
              }}
            />
          </ChartCard>
        </RowWrapper>

        <RowWrapper>
          <ChartCard title="Виновник ДТП">
            <QueryRenderer
              query={{
                measures: ["DtpKgf.count"],
                dimensions: ["DtpKgf.fd1r14p1"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <PieChart
                    loading={!resultSet}
                    id="DtpKgfGuiltyPie"
                    colorList={PIE_COLORS}
                    {...generatePieData(resultSet)}
                    height="400px"
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard title="Состояние водителя">
            <QueryRenderer
              query={{
                measures: ["DtpKgf.count"],
                dimensions: ["DtpKgf.fd1r141p1"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <PieChart
                    loading={!resultSet}
                    id="DtpKgfDriverConditionPie"
                    colorList={PIE_COLORS}
                    {...generatePieData(resultSet)}
                    height="400px"
                  />
                );
              }}
            />
          </ChartCard>
        </RowWrapper>

        <RowWrapper>
          <ChartCard title="Карта">
            <RadioGroup
              defaultValue="a"
              onChange={(value) =>
                this.setState({
                  mapType: value.target.value,
                })
              }
              list={[
                { key: "a", value: "Карта" },
                { key: "b", value: "Тепловая карта" },
              ]}
            />
            <QueryRenderer
              query={{
                dimensions: ["DtpKgf.id", "DtpKgf.x", "DtpKgf.y"],
                renewQuery: true,
                filters,
                timeDimensions: [
                  {
                    dimension: "DtpKgf.rtaDate",
                    dateRange: range,
                    granularity: "month",
                  },
                ],
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return <Skeleton />;
                const rawData = resultSet.rawData();
                return (
                  <Fragment>
                    {mapType === "a" ? (
                      <YandexMapWrapDetail
                        cubejsApi={cubejsApi}
                        type="DtpKgf.fd1r141p1"
                        showDetails
                        query={{
                          dimensions: [
                            "DtpKgf.id",
                            "DtpKgf.rtaType",
                            "DtpKgf.rtaDate",
                            "DtpKgf.fd1r06p2",
                            "DtpKgf.fd1r071p1",
                            "DtpKgf.fd1r07p2",
                            "DtpKgf.fd1r07p4",
                            "DtpKgf.fd1r09p1",
                            "DtpKgf.fd1r141p1",
                            "DtpKgf.fd1r14p1",
                            "DtpKgf.vehicleCategory",
                            "DtpKgf.isPublicTransport",
                          ],
                        }}
                        renderDetail={(items) => (
                          <Table
                            dataSource={items}
                            pagination={false}
                            rowKey="id"
                            size="small"
                          >
                            <Table.Column
                              title="Вид ДТП"
                              dataIndex="DtpKgf.rtaType"
                              width={100}
                            />
                            <Table.Column
                              title="Дата"
                              dataIndex="DtpKgf.rtaDate"
                              render={(date) => formatDateTime(date)}
                            />
                            <Table.Column
                              title="Район"
                              dataIndex="DtpKgf.fd1r06p2"
                              width={100}
                            />
                            <Table.Column
                              title="Погодные условия"
                              dataIndex="DtpKgf.fd1r071p1"
                            />
                            <Table.Column
                              title="Время суток"
                              dataIndex="DtpKgf.fd1r07p2"
                              width={100}
                            />
                            <Table.Column
                              title="Место столкновения"
                              dataIndex="DtpKgf.fd1r07p4"
                            />
                            <Table.Column
                              title="Нарушение"
                              dataIndex="DtpKgf.fd1r09p1"
                              width={100}
                            />
                            <Table.Column
                              title="Состояние водителя"
                              dataIndex="DtpKgf.fd1r141p1"
                            />
                            <Table.Column
                              title="Виновник"
                              dataIndex="DtpKgf.fd1r14p1"
                              width={100}
                            />
                            <Table.Column
                              title="Категория прав"
                              dataIndex="DtpKgf.vehicleCategory"
                            />
                            <Table.Column
                              title="Тип транспорта (общественный или частный)"
                              dataIndex="DtpKgf.isPublicTransport"
                            />
                          </Table>
                        )}
                        data={rawData}
                        center={[47.1167, 51.8833]}
                        idDataKey="DtpKgf.id"
                        xDataKey="DtpKgf.x"
                        yDataKey="DtpKgf.y"
                      />
                    ) : (
                      <YandexHeatMapWrap
                        center={[47.1167, 51.8833]}
                        objects={rawData}
                        idDataKey="DtpKgf.id"
                        xDataKey="DtpKgf.x"
                        yDataKey="DtpKgf.y"
                        dateRange={range}
                        heatMap={true}
                        zoom={11}
                      />
                    )}
                  </Fragment>
                );
              }}
            />
          </ChartCard>
        </RowWrapper>
      </MainWrapper>
    );
  }
}

export default Dtp;
