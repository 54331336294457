import React, { Component, Fragment } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Button, Icon, Table, Skeleton } from "antd";

import cubejs from "../../cube";
import moment from "moment";
import { MainWrapper } from "../../components/newDesign/MainWrapper";
import { TabMainTitle } from "../../components/newDesign/TabMainTitle";
import { FilterWrapper } from "../../components/newDesign/FilterWrapper";
import { FilterItem } from "../../components/newDesign/FilterItem";
import { SelectList } from "../../components/newDesign/SelectList";
import { RowWrapper } from "../../components/newDesign/RowWrapper";
import { ChartCard } from "../../components/newDesign/ChartCard";
import { LineChart } from "../../components/visualizations";
import { generateLineData } from "../../utils/visualizations";

const cubejsApi = cubejs({ appId: 1 });

// const icons = {
//   Куры: "1",
//   "Соль, кроме экстра": "1",
//   "Молоко пастеризованное 2,5% жирности": "1",
//   "Хлеб ржано-пшеничный": "1",
//   "Кефир 2,5% жирности": "1",
//   "Яйца, 1 и 2 категори": "1",
//   "Масло подсолнечное": "1",
//   "Творог 5-9% жирности": "3",
//   "Говядина лопаточно-грудная часть": "19",
//   "Мука пшеничная высшего сорта": "1",
//   Рожки: "18",
//   "Сахар-песок": "4",
//   "Бензин АИ-98": "2",
//   Ампицилин: "1",
//   "Бинт медицинский": "1",
//   "Бензин АИ-95, АИ-96": "20",
//   "Рис шлифованный, полированный": "13",
//   Морковь: "10",
//   "Витаминные препараты": "1",
//   "Крупа гречневая": "1",
//   "Лук репчатый": "1",
//   "Мука пшеничная первого сорта": "1",
//   Пенталгин: "1",
//   Картофель: "9",
//   "Масло сливочное несоленое": "15",
//   "Капуста белокочанная": "12",
//   "Бензин АИ-80": "6",
// };

class PublicCatering extends Component {
  state = {
    categories: [],
    regions: [],
    years: [],
    selectedProduct: null,
    showDetails: false,
    region: "г.Караганда",
    year: 2019,
  };

  async componentDidMount() {
    const [categories, regions, years] = await Promise.all([
      cubejsApi.load({
        measures: ["ProductBasket.price"],
        dimensions: [
          "ProductBasket.nameRu",
          "ProductBasket.unittype",
          "ProductTypes.pictureId",
        ],
        timeDimensions: [
          {
            dimension: "ProductBasket.date",
            granularity: "month",
          },
        ],
        order: {
          "ProductBasket.date": "desc",
        },
        limit: 20,
        renewQuery: true,
      }),
      cubejsApi.load({
        dimensions: ["ProductBasket.district"],
      }),
      cubejsApi.load({
        dimensions: ["ProductBasket.year"],
        order: {
          "ProductBasket.year": "desc",
        },
      }),
    ]);
    this.setState({
      categories: categories.rawData(),
      regions: regions.rawData(),
      years: years.rawData(),
    });
  }

  showDetailsClick = (name) => {
    this.setState({
      selectedProduct: name,
      showDetails: true,
    });
  };

  resetSelection = () => {
    this.setState({
      selectedProduct: null,
      showDetails: false,
    });
  };

  render() {
    const {
      categories,
      regions,
      years,
      selectedProduct,
      showDetails,
      region,
      year,
    } = this.state;

    const filters = [
      {
        member: "ProductBasket.nameRu",
        operator: "equals",
        values: [selectedProduct],
      },
      {
        member: "ProductBasket.district",
        operator: "equals",
        values: [region.toString()],
      },
      {
        member: "ProductBasket.year",
        operator: "equals",
        values: [year.toString()],
      },
    ];
    return (
      <MainWrapper>
        <TabMainTitle>
          Мониторинг социальных цен на продукты питания
        </TabMainTitle>
        <div style={{ minHeight: "100vh", padding: 10 }}>
          {showDetails ? (
            <Fragment>
              <Button onClick={this.resetSelection}>
                <Icon type="left" />
                {selectedProduct}
              </Button>
              <FilterWrapper>
                <FilterItem>
                  <SelectList
                    label={"Регион:"}
                    onChange={(value) => this.setState({ region: value })}
                    valueKey="ProductBasket.district"
                    showKey="ProductBasket.district"
                    list={regions}
                  />
                </FilterItem>

                <FilterItem>
                  <SelectList
                    label={"Год:"}
                    onChange={(value) => this.setState({ year: value })}
                    valueKey="ProductBasket.year"
                    showKey="ProductBasket.year"
                    list={years}
                  />
                </FilterItem>
              </FilterWrapper>

              <RowWrapper>
                <ChartCard title="Таблица">
                  {" "}
                  <QueryRenderer
                    query={{
                      measures: ["ProductBasket.price"],
                      dimensions: ["ProductBasket.date.month"],
                      filters,
                      order: {
                        "ProductBasket.date": "asc",
                      },
                    }}
                    cubejsApi={cubejsApi}
                    render={({ resultSet }) => {
                      if (!resultSet) return <Skeleton />;
                      return (
                        <Table
                          dataSource={resultSet.rawData()}
                          pagination={false}
                          rowKey="id"
                          size="small"
                        >
                          <Table.Column
                            title="Месяц"
                            dataIndex="ProductBasket.date"
                            align="center"
                            render={(date) => moment(date).format("MMMM")}
                          />
                          <Table.Column
                            title="Цена"
                            dataIndex="ProductBasket.price"
                            align="center"
                          />
                        </Table>
                      );
                    }}
                  />
                </ChartCard>
                <ChartCard title="График">
                  <QueryRenderer
                    query={{
                      measures: ["ProductBasket.price"],
                      filters,
                      timeDimensions: [
                        {
                          dimension: "ProductBasket.date",
                          granularity: "month",
                        },
                      ],
                    }}
                    cubejsApi={cubejsApi}
                    render={({ resultSet }) => {
                      return (
                        <LineChart
                          loading={!resultSet}
                          id="LineChartFoodBasket"
                          {...generateLineData(resultSet)}
                          height="530px"
                          dateAxis
                        />
                      );
                    }}
                  />
                </ChartCard>
              </RowWrapper>
            </Fragment>
          ) : (
            <div className="mt20">
              <RowWrapper>
                {categories.map((c, index) => {
                  const iconId = c["ProductTypes.pictureId"];
                  return (
                    <div
                      className="food_item"
                      onClick={() =>
                        this.showDetailsClick(c["ProductBasket.nameRu"])
                      }
                      key={index}
                    >
                      <div>{c["ProductBasket.nameRu"]}</div>
                      <div>
                        <img
                          src={`./images/food/${iconId}.${
                            iconId === 33 ? "png" : "svg"
                          }`}
                          alt=""
                        />
                      </div>
                      <div className="food_item__price">
                        {c["ProductBasket.price"]}
                      </div>
                      <div className="food_item__unittype">
                        {c["ProductBasket.unittype"]}
                      </div>
                    </div>
                  );
                })}
              </RowWrapper>
            </div>
          )}
        </div>
      </MainWrapper>
    );
  }
}

export default PublicCatering;
