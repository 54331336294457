import React from "react";
import PropTypes from "prop-types";

import Loader from "../../../components/Loader";
import styles from "./index.module.scss";

export default function SingleNumber({ children, number, title, height }) {
  if (!children && !number && number !== 0)
    return (
      <div className={styles.singleNumber} style={{ height }}>
        <Loader />
      </div>
    );
  return (
    <div className={styles.singleNumber} style={{ height }}>
      <div className={styles.title}>{title}</div>
      <div className={styles.number}>{children ? children : number}</div>
    </div>
  );
}

SingleNumber.defaultProps = {
  // height: "60px"
};

SingleNumber.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.number,
};
