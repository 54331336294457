import { Tooltip } from "antd";
import moment from "moment";
import React from "react";

const renderRowColor = (text, record) => {
  return {
    props: {
      style: { color: "#fff" },
    },
    children: <span>{text}</span>,
  };
};

const renderRowColorAndWordBreak = (text, record, maxWidth) => {
  return {
    props: {
      style: { color: "#fff" },
    },
    children: (
      <Tooltip title={text}>
        <div
          style={{
            whiteSpace: "pre",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: maxWidth,
          }}
        >
          {text}
        </div>
      </Tooltip>
    ),
  };
};

export const fields = [
  {
    title: "№",
    dataIndex: "number",
  },
  {
    title: "Номер в системе",
    dataIndex: "Appeals.appealNum",
  },
  {
    title: "Источник",
    dataIndex: "Appeals.source",
  },
  {
    title: "Тип обращения",
    dataIndex: "Appeals.appealType",
  },
  {
    title: "Категория",
    dataIndex: "Appeals.category",
  },
  {
    title: "Подкатегория обращения",
    dataIndex: "Appeals.subCategory",
  },
  {
    title: "Текст обращения",
    dataIndex: "Appeals.appealText",
  },
  {
    title: "Дата подачи",
    dataIndex: "Appeals.submissionDate",
  },
  {
    title: "Время подачи",
    dataIndex: "Appeals.submissionTime",
  },
  {
    title: "Планируемая дата закрытия",
    dataIndex: "Appeals.plannedDate",
  },
  {
    title: "Дата закрытия (фактическая)",
    dataIndex: "Appeals.replyDate",
  },
  {
    title: "Статус обращения",
    dataIndex: "Appeals.theStatus",
  },
  {
    title: "Статус исполнения",
    dataIndex: "statusDone",
  },
  {
    title: "Количество дней просрочки",
    dataIndex: "Appeals.replyDays",
  },
  {
    title: "Регион",
    dataIndex: "Appeals.region",
  },
  {
    title: "Населенный пункт",
    dataIndex: "Appeals.district",
  },
  {
    title: "ФИО",
    dataIndex: "Appeals.fio",
  },
  {
    title: "Телефон",
    dataIndex: "Appeals.phoneNumber",
  },
  {
    title: "Ответ по обращению",
    dataIndex: "Appeals.answer",
  },
];

export const STATUS_BY_COLOR = {
  green: "Завершено в срок",
  blue: "На исполнении",
  red: "Просрочено",
};

export const columns = [
  {
    title: "№",
    dataIndex: "number",
    key: "number",
    width: 50,
    sorter: (a, b) => {
      return a.number - b.number;
    },
  },
  {
    title: "Номер в системе",
    dataIndex: "Appeals.appealNum",
    key: "appealNum",
    width: 150,
    render: renderRowColor,
    sorter: (a, b) => {
      return a["Appeals.appealNum"].localeCompare(b["Appeals.appealNum"]);
    },
  },
  {
    title: "Источник",
    dataIndex: "Appeals.source",
    key: "source",
    width: 150,
    render: renderRowColor,
    sorter: (a, b) => {
      return a["Appeals.source"].localeCompare(b["Appeals.source"]);
    },
  },
  {
    title: "Тип обращения",
    dataIndex: "Appeals.appealType",
    key: "appealType",
    width: 150,
    render: renderRowColor,
    sorter: (a, b) => {
      return a["Appeals.appealType"].localeCompare(b["Appeals.appealType"]);
    },
  },
  {
    title: "Категория",
    dataIndex: "Appeals.category",
    key: "category",
    width: 150,
    render: (text, record) => renderRowColor(text, record, "15ch"),
    sorter: (a, b) => {
      return a["Appeals.category"].localeCompare(b["Appeals.category"]);
    },
  },
  {
    title: "Подкатегория обращения",
    dataIndex: "Appeals.subCategory",
    key: "subCategory",
    width: 150,
    render: (text, record) => renderRowColor(text, record, "15ch"),
    sorter: (a, b) => {
      return a["Appeals.subCategory"].localeCompare(b["Appeals.subCategory"]);
    },
  },
  {
    title: "Текст обращения",
    dataIndex: "Appeals.appealText",
    key: "appealText",
    width: 300,
    textWrap: "word-break",
    render: (text, record) => renderRowColorAndWordBreak(text, record, "35ch"),
    sorter: (a, b) => {
      return a["Appeals.appealText"].localeCompare(b["Appeals.appealText"]);
    },
  },
  {
    title: "Дата подачи",
    dataIndex: "Appeals.submissionDate",
    key: "submissionDate",
    width: 175,
    render: renderRowColor,
    sorter: (a, b) => {
      return (
        new Date(a["Appeals.submissionDate"]) -
        new Date(b["Appeals.submissionDate"])
      );
    },
  },
  {
    title: "Время подачи",
    dataIndex: "Appeals.submissionTime",
    key: "submissionTime",
    width: 175,
    render: renderRowColor,
    sorter: (a, b) => {
      return a["Appeals.submissionTime"].localeCompare(
        b["Appeals.submissionTime"]
      );
    },
  },
  {
    title: "Планируемая дата закрытия",
    dataIndex: "Appeals.plannedDate",
    key: "plannedDate",
    width: 175,
    render: renderRowColor,
    sorter: (a, b) => {
      return (
        new Date(a["Appeals.plannedDate"]) - new Date(b["Appeals.plannedDate"])
      );
    },
  },
  {
    title: "Дата закрытия (фактическая)",
    dataIndex: "Appeals.replyDate",
    key: "replyDate",
    width: 175,
    render: (d) => (d ? moment(d).format("DD.MM.YYYY") : "В обработке"),

    // render: renderRowColor,
    sorter: (a, b) => {
      return (
        new Date(a["Appeals.replyDate"]) - new Date(b["Appeals.replyDate"])
      );
    },
  },
  {
    title: "Статус обращения",
    dataIndex: "Appeals.theStatus",
    key: "theStatus",
    width: 150,
    render: renderRowColor,
    sorter: (a, b) => {
      return a["Appeals.theStatus"].localeCompare(b["Appeals.theStatus"]);
    },
  },
  {
    title: "Статус исполнения",
    dataIndex: "statusDone",
    key: "statusDone",
    width: 150,
    render: renderRowColor,
    sorter: (a, b) => {
      return a["statusDone"].localeCompare(b["statusDone"]);
    },
  },
  {
    title: "Количество дней просрочки",
    dataIndex: "Appeals.replyDays",
    key: "replyDays",
    width: 150,
    sorter: (a, b) => a["Appeals.replyDays"] - b["Appeals.replyDays"],
    render: renderRowColor,
  },
  {
    title: "Регион",
    dataIndex: "Appeals.region",
    key: "region",
    width: 150,
    sorter: (a, b) => a["Appeals.region"] - b["Appeals.region"],
    render: renderRowColor,
  },
  {
    title: "Населенный пункт",
    dataIndex: "Appeals.district",
    key: "district",
    width: 150,
    sorter: (a, b) => a["Appeals.district"] - b["Appeals.district"],
    render: renderRowColor,
  },
  {
    title: "ФИО",
    dataIndex: "Appeals.fio",
    key: "fio",
    width: 150,
    sorter: (a, b) => a["Appeals.fio"] - b["Appeals.fio"],
    render: renderRowColor,
  },
  {
    title: "Телефон",
    dataIndex: "Appeals.phoneNumber",
    key: "phoneNumber",
    width: 100,
    sorter: (a, b) => a["Appeals.phoneNumber"] - b["Appeals.phoneNumber"],
    render: renderRowColor,
  },
  {
    title: "Ответ по обращению",
    dataIndex: "Appeals.answer",
    key: "answer",
    width: 300,
    render: (text, record) => renderRowColorAndWordBreak(text, record, "35ch"),
    sorter: (a, b) => a["Appeals.answer"] - b["Appeals.answer"],
  },
];

export const coordinatesFilter = [
  {
    member: "Appeals.x",
    operator: "set",
  },
  {
    member: "Appeals.y",
    operator: "set",
  },
];
