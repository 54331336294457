import moment from 'moment';
import numeral from 'numeral';

// load a locale
numeral.register('locale', 'ru', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'тыс',
    million: 'млн',
    billion: 'млрд',
    trillion: 'трлн'
  },
  currency: {
    symbol: 'т'
  }
});

// switch between locales
numeral.locale('ru');

export const numberFormatter = item => numeral(item).format('0.00 a');

export const formatDate = (date, format = 'DD.MM.YYYY') => {
  return date ? moment(date).format(format) : '-';
};

export const formatDateTime = date => {
  return moment(date).format('DD.MM.YYYY HH:mm:ss');
};

// Adds the thousands separator
export const numberWithThousands = x => {
  return x.toLocaleString('ru');
};

export const numberWithCommas = (x, delimitter = ',') => {
  return (Math.round(x * 10) / 10)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, delimitter);
};
