import React, { Component } from "react";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";
import { Icon, Divider, Statistic, Card } from "antd";

import cubejs from "../../cube";
import { formatDateTime } from "../../utils/helpers";
import { MainWrapper } from "../../components/newDesign/MainWrapper";
import { FilterWrapper } from "../../components/newDesign/FilterWrapper";
import { FilterItem } from "../../components/newDesign/FilterItem";
import { SelectList } from "../../components/newDesign/SelectList";
import { RowWrapper } from "../../components/newDesign/RowWrapper";
import { ChartCard } from "../../components/newDesign/ChartCard";

const cubejsApi = cubejs({ appId: 1 });
const todayDimension = {
  dimension: "WeatherView.validDate",
  dateRange: [moment().startOf("day"), moment().endOf("day")],
  granularity: "day",
};
const weekDimension = {
  dimension: "WeatherView.validDate",
  dateRange: [moment().startOf("day"), moment().add(4, "d").endOf("day")],
  granularity: "day",
};

const windDirections = {
  С: 360,
  ССВ: 22.5,
  СВ: 45,
  ВСВ: 67.5,
  В: 90,
  ВЮВ: 112.5,
  ЮВ: 135,
  ЮЮВ: 157.5,
  Ю: 180,
  ЮЮЗ: 202.5,
  ЮЗ: 225,
  ЗЮЗ: 247.5,
  З: 270,
  ЗСЗ: 292.5,
  СЗ: 315,
  ССЗ: 337.5,
};

export default class Ecology2 extends Component {
  state = {
    selectedCity: 343,
    notifications: [],
    filtersData: {
      "WeatherView.region": {
        key: "WeatherView.region",
        title: "Город",
        options: [],
        values: ["Караганда Г.А."],
      },
    },
  };

  async componentDidMount() {
    let { selectedCity, filtersData } = this.state;
    this.loadNotifications(selectedCity);
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({
      filtersData,
    });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  handleChange = (value) => {
    this.setState({ selectedCity: value });
  };

  loadNotifications = async (value) => {
    try {
      const notifRes = await fetch(`https://cc.krgsc.kz/data/darmen`);
      const notifData = await notifRes.json();
      const notifications = notifData.data.model[0].notifications;
      this.setState({
        selectedCity: value,
        notifications,
      });
    } catch (e) {
      this.setState({
        selectedCity: value,
        notifications: [],
      });
    }
  };

  render() {
    const { notifications, filtersData } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });

    return (
      <MainWrapper>
        <FilterWrapper>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <FilterItem>
                <SelectList
                  label={filter.title + ":"}
                  onChange={(value) => this.applyFilters(filter.key, [value])}
                  allowClear
                  size="small"
                  list={filter.options}
                />
              </FilterItem>
            );
          })}
        </FilterWrapper>

        <RowWrapper>
          <ChartCard title={"Температура"}>
            <QueryRenderer
              query={{
                measures: [
                  "WeatherView.dayTemperature",
                  "WeatherView.nightTemperature",
                  "WeatherView.dayCloudPct",
                  "WeatherView.dayHumidityPct",
                ],
                dimensions: ["WeatherView.dayUvDescription"],
                timeDimensions: [todayDimension],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return null;
                const data = resultSet.rawData()[0];
                const day = data["WeatherView.dayTemperature"]
                  ? Number(data["WeatherView.dayTemperature"])
                  : null;
                const night = data["WeatherView.nightTemperature"]
                  ? Number(data["WeatherView.nightTemperature"])
                  : null;
                const avg = day && night ? (day + night) / 2 : "--";
                const diff = day && night ? day - night : "--";
                return (
                  <div className="card-content flex" style={{ padding: 10 }}>
                    <div className="circleData">
                      <div className="temperature-values">
                        <span className="temperature-max">
                          {data["WeatherView.dayTemperature"]
                            ? `${Number(
                                data["WeatherView.dayTemperature"]
                              ).toFixed(0)}°`
                            : "--"}
                        </span>
                        <Divider type="vertical" />
                        <span className="temperature-min">
                          {data["WeatherView.nightTemperature"]
                            ? `${Number(
                                data["WeatherView.nightTemperature"]
                              ).toFixed(0)}°`
                            : "--"}
                        </span>
                      </div>
                      <div className="temperature-current">{avg}°</div>
                      <div className="temperature-values">
                        <Icon type="arrow-up" />
                        <Divider type="vertical" />
                        0.5°
                      </div>
                    </div>
                    <div className="asideData">
                      <Statistic
                        title="Покрытие облаками"
                        className="light-blue value small-text"
                        value={
                          data["WeatherView.dayCloudPct"]
                            ? `${Number(
                                data["WeatherView.dayCloudPct"]
                              ).toFixed(1)}%`
                            : "--"
                        }
                      />
                      <Statistic
                        title="Уровень ультрафиолетового излучения"
                        className="light-blue value small-text"
                        value={
                          data["WeatherView.dayUvDescription"]
                            ? data["WeatherView.dayUvDescription"]
                            : "--"
                        }
                      />
                      <Statistic
                        title="Влажность воздуха"
                        className="light-green value small-text"
                        value={
                          data["WeatherView.dayHumidityPct"]
                            ? `${Number(
                                data["WeatherView.dayHumidityPct"]
                              ).toFixed(1)}%`
                            : "--"
                        }
                      />
                      <Statistic
                        title="Разница температуры"
                        className="light-blue value small-text"
                        value={`${diff}°`}
                      />
                    </div>
                  </div>
                );
              }}
            />
          </ChartCard>
          <ChartCard title={"Прогноз погоды"} style={{ flex: 2 }}>
            <QueryRenderer
              query={{
                measures: [
                  "WeatherView.dayTemperature",
                  "WeatherView.nightTemperature",
                  "WeatherView.dayWindSpeed",
                  "WeatherView.nightWindSpeed",
                ],
                dimensions: [
                  "WeatherView.dayWindDirCompass",
                  "WeatherView.nightWindDirCompass",
                  "WeatherView.dayOfWeek",
                  "WeatherView.weatherImage",
                ],
                timeDimensions: [weekDimension],
                filters,
                // order: {
                //   "WeatherView.validDate": "desc"
                // }
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return null;
                const data = resultSet.rawData();
                return (
                  <div className="card-content flex" style={{ padding: 10 }}>
                    {data.map((d) => (
                      <Card className="day-forecast-card">
                        <div className="day-forecast-temperature">
                          {d["WeatherView.dayOfWeek"]}
                        </div>
                        <div className="day-forecast-image">
                          <img
                            alt="Дневной прогноз"
                            src={d["WeatherView.weatherImage"]}
                          />
                        </div>
                        <div className="day-forecast-values">
                          <span className="temperature-day">
                            {d["WeatherView.dayTemperature"]
                              ? `${Number(
                                  d["WeatherView.dayTemperature"]
                                ).toFixed(1)}°`
                              : "--"}
                          </span>
                          <Divider type="vertical" />
                          <span className="temperature-night">
                            {d["WeatherView.nightTemperature"]
                              ? `${Number(
                                  d["WeatherView.nightTemperature"]
                                ).toFixed(1)}°`
                              : "--"}
                          </span>
                        </div>
                        <div className="day-forecast-wind">
                          <div className="day-forecast-wind-day">
                            <span className="wind-value">
                              {d["WeatherView.dayWindSpeed"]
                                ? Number(d["WeatherView.dayWindSpeed"]).toFixed(
                                    1
                                  )
                                : "--"}
                            </span>
                            <span className="wind-metric"> km/h</span>
                          </div>
                          <Divider type="vertical" />
                          <div className="day-forecast-wind-night">
                            <span className="wind-value">
                              {d["WeatherView.nightWindSpeed"]
                                ? Number(
                                    d["WeatherView.nightWindSpeed"]
                                  ).toFixed(1)
                                : "--"}
                            </span>
                            <span className="wind-metric"> km/h</span>
                          </div>
                        </div>
                        <div className="day-forecast-side">
                          <div className="day-forecast-side-day">
                            <Icon type="pie-chart" />{" "}
                            {d["WeatherView.dayWindDirCompass"]
                              ? d["WeatherView.dayWindDirCompass"]
                              : "--"}
                          </div>
                          <Divider type="vertical" />

                          <div className="day-forecast-side-night">
                            <Icon type="pie-chart" />{" "}
                            {d["WeatherView.nightWindDirCompass"]
                              ? d["WeatherView.nightWindDirCompass"]
                              : "--"}
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                );
              }}
            />
          </ChartCard>
        </RowWrapper>
        <RowWrapper>
          <ChartCard title={"Ветер"}>
            <QueryRenderer
              query={{
                measures: [
                  "WeatherView.dayUvIndex",
                  "WeatherView.dayWindSpeed",
                ],
                dimensions: [
                  "WeatherView.dayUvDescription",
                  "WeatherView.dayWindDirDegrees",
                  "WeatherView.dayWindDirCompass",
                ],
                timeDimensions: [todayDimension],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return null;
                const data = resultSet.rawData()[0];
                // return <pre>{JSON.stringify(data, null, 2)}</pre>;
                return (
                  <div className="weather-panel spaced" style={{ padding: 10 }}>
                    <div className="panel">
                      <div>
                        УФ индекс: <br />
                        {data["WeatherView.dayUvIndex"]
                          ? `${Number(data["WeatherView.dayUvIndex"]).toFixed(
                              0
                            )}`
                          : "--"}
                      </div>

                      <div>
                        УФ излучение: <br />
                        {data["WeatherView.dayUvDescription"]
                          ? data["WeatherView.dayUvDescription"]
                          : "--"}
                      </div>
                    </div>

                    <div className="panel-circle">
                      <div style={{ fontSize: 36 }}>
                        {data["WeatherView.dayWindDirDegrees"]
                          ? `${data["WeatherView.dayWindDirDegrees"]}°`
                          : "--"}
                      </div>
                      <div>
                        {data["WeatherView.dayWindDirCompass"]
                          ? data["WeatherView.dayWindDirCompass"]
                          : "--"}
                      </div>
                      <div
                        className="arrow"
                        style={{
                          transform: `rotate(${
                            windDirections[
                              data["WeatherView.dayWindDirCompass"]
                            ]
                          }deg)`,
                        }}
                      ></div>
                    </div>

                    <div className="panel">
                      <div>
                        Направление ветра: <br />
                        {data["WeatherView.dayWindDirDegrees"]
                          ? `${data["WeatherView.dayWindDirDegrees"]}°`
                          : "--"}
                      </div>

                      <div>
                        Скорость ветра: <br />
                        {data["WeatherView.dayWindSpeed"]
                          ? `${Number(data["WeatherView.dayWindSpeed"]).toFixed(
                              1
                            )}`
                          : "--"}
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </ChartCard>
          <ChartCard title={"Фазы луны"}>
            <div className="weather-panel" style={{ padding: 10 }}>
              <div className="panel">
                <QueryRenderer
                  query={{
                    dimensions: ["WeatherView.moonRise"],
                    timeDimensions: [todayDimension],
                    filters,
                  }}
                  cubejsApi={cubejsApi}
                  render={({ resultSet }) => {
                    if (!resultSet) return null;
                    return (
                      <div>
                        Восход луны: <br />
                        {moment(
                          resultSet.rawData()[0]["WeatherView.moonRise"]
                        ).format("HH:mm")}
                      </div>
                    );
                  }}
                />
                <QueryRenderer
                  query={{
                    dimensions: ["WeatherView.moonset"],
                    timeDimensions: [todayDimension],
                    filters,
                  }}
                  cubejsApi={cubejsApi}
                  render={({ resultSet }) => {
                    if (!resultSet) return null;
                    return (
                      <div>
                        Заход луны: <br />
                        {moment(
                          resultSet.rawData()[0]["WeatherView.moonset"]
                        ).format("HH:mm")}
                      </div>
                    );
                  }}
                />
              </div>

              <div className="panel-image">
                <QueryRenderer
                  query={{
                    dimensions: ["WeatherView.moonIcon"],
                    timeDimensions: [todayDimension],
                    filters,
                  }}
                  cubejsApi={cubejsApi}
                  render={({ resultSet }) => {
                    if (!resultSet) return null;
                    return (
                      <img
                        alt="Фаза луны"
                        src={`/images/moons/${
                          resultSet.rawData()[0]["WeatherView.moonIcon"]
                        }.png`}
                        style={{ width: 160 }}
                      />
                    );
                  }}
                />
              </div>

              <div className="panel">
                <QueryRenderer
                  query={{
                    measures: ["WeatherView.maxValidDate"],
                    filters: [
                      ...filters,
                      {
                        member: "WeatherView.moonIcon",
                        operator: "equals",
                        values: ["F"],
                      },
                    ],
                  }}
                  cubejsApi={cubejsApi}
                  render={({ resultSet }) => {
                    if (!resultSet) return null;
                    return (
                      <div>
                        Предыдущая полная луна: <br />
                        {moment(
                          resultSet.rawData()[0]["WeatherView.maxValidDate"]
                        ).format("DD.MM.YYYY")}
                      </div>
                    );
                  }}
                />
                <QueryRenderer
                  query={{
                    measures: ["WeatherView.maxValidDate"],
                    filters: [
                      ...filters,
                      {
                        member: "WeatherView.moonIcon",
                        operator: "equals",
                        values: ["N"],
                      },
                    ],
                  }}
                  cubejsApi={cubejsApi}
                  render={({ resultSet }) => {
                    if (!resultSet) return null;
                    return (
                      <div>
                        Следующая новая луна: <br />
                        {moment(
                          resultSet.rawData()[0]["WeatherView.maxValidDate"]
                        ).format("DD.MM.YYYY")}
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </ChartCard>
          <ChartCard title={"Солнце"}>
            <QueryRenderer
              query={{
                dimensions: [
                  "WeatherView.sunset.day",
                  // "WeatherView.sunrise.day"
                ],
                // timeDimensions: [todayDimension],
                filters,
                // : [...filters, todayFilter]
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return null;
                return (
                  <div className="weather-panel spaced" style={{ padding: 10 }}>
                    <div className="panel">
                      <div>
                        Полный дневной свет: <br />
                        14:22
                      </div>

                      <div>
                        Восход солнца: <br />
                        6:04
                      </div>
                    </div>

                    <div className="panel-circle">
                      <div style={{ fontSize: 36 }}>11:37</div>
                      <div>до захода солнца</div>
                    </div>

                    <div className="panel">
                      <div>
                        Полный мрак: <br />
                        9:37
                      </div>

                      <div>
                        Заход солнца: <br />
                        20:29
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </ChartCard>
          <div style={{ flex: 1 }}>
            {/* <SelectList
              label={"Город:"}
              defaultValue={selectedCity}
              size="small"
              onChange={this.loadNotifications}
              showKey="key"
              valueKey="value"
              list={Object.entries(LOCATIONS).map(([key, value]) => ({
                key,
                value,
              }))}
            /> */}
            {notifications.map((n) => (
              <div className="notification">
                <div className="location">{n.location_name}</div>
                <div className="date">{formatDateTime(n.created_at)}</div>
                <div className="title">{n.title}</div>
                <div className="text">
                  <p dangerouslySetInnerHTML={{ __html: n.text }}></p>
                </div>
              </div>
            ))}
          </div>
        </RowWrapper>
      </MainWrapper>
    );
  }
}
