import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

function MapLegend() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={classNames(styles.block, styles.executed)}>
          Завершено в срок
        </div>
        <div className={classNames(styles.block, styles.onExecution)}>
          На исполнении
        </div>
        <div className={classNames(styles.block, styles.expired)}>
          Просрочено
        </div>
      </div>
    </div>
  );
}

export default MapLegend;
