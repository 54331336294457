import React, { Component } from "react";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";
import { DatePicker, Statistic, Card, Row, Col, Table, Select } from "antd";

import cubejs from "../../cube";
import { PieChart } from "../../components/visualizations";
import ExportExcel from "../../components/ExportExcel";
import FromStart from "./FromStart";
import { formatDate } from "../../utils/helpers";
import LoadingCss from "../../common/LoadingCss";
import CustomModal from "./CustomModal";
import { numberWithCommas } from "../../utils/helpers";
const colors = ["#265e64", "#0FABBC", "#E4F9FF"];

const detailQuery = {
  dimensions: [
    "Appeals.appealNum",
    "Appeals.appealType",
    "Appeals.expired",
    "Appeals.answerType",
    "Appeals.executor",
    "Appeals.category",
    "Appeals.subCategory",
    "Appeals.submissionDate",
    "Appeals.replyDate",
    "Appeals.source",
    "Appeals.submissionTime",
  ],
};

const fields = [
  { title: "№", dataIndex: "id" },
  { title: "Рег. номер обращения", dataIndex: "Appeals.appealNum" },
  { title: "Тип", dataIndex: "Appeals.appealType" },
  { title: "Тип подачи", dataIndex: "Appeals.source" },
  { title: "Истек", dataIndex: "Appeals.expired" },
  { title: "Способ ответа", dataIndex: "Appeals.answerType" },
  { title: "Исполнитель", dataIndex: "Appeals.executor" },
  { title: "Категория", dataIndex: "Appeals.category" },
  { title: "Подкатегория", dataIndex: "Appeals.subCategory" },
  { title: "Дата подачи", dataIndex: "Appeals.submissionDate" },
  { title: "Время подачи", dataIndex: "Appeals.submissionTime" },
  { title: "Дата закрытия обращения", dataIndex: "Appeals.replyDate" },
];

const renderDetail = (items) => (
  <Table
    rowClassName={(record) =>
      record["Appeals.expired"] === "Просрочено" && "row error"
    }
    dataSource={items}
    rowKey="id"
    size="small"
    // scroll={{ x: 1300, y: "60vh" }}
    // scroll={{ x: '1500px',y: "60vh" }}
    scroll={{ y: "60vh", x: "max-content" }}
    pagination={{
      defaultPageSize: 100,
      pageSizeOptions: ["10", "20", "50", "100"],
      showSizeChanger: true,
      locale: { items_per_page: "" },
    }}
  >
    <Table.Column title="№" dataIndex="id" />
    <Table.Column
      title="Рег. номер обращения"
      dataIndex="Appeals.appealNum"
      key="Appeals.appealNum"
      width={150}
    />
    <Table.Column width={100} title="Тип" dataIndex="Appeals.appealType" />
    <Table.Column width={150} title="Тип подачи" dataIndex="Appeals.source" />
    {/* <Table.Column width={150} title="Истек" dataIndex="Appeals.expired" /> */}
    <Table.Column
      width={150}
      title="Способ ответа"
      dataIndex="Appeals.answerType"
    />
    <Table.Column
      width={150}
      title="Исполнитель"
      dataIndex="Appeals.executor"
    />
    <Table.Column width={200} title="Категория" dataIndex="Appeals.category" />
    <Table.Column
      width={150}
      title="Подкатегория"
      dataIndex="Appeals.subCategory"
    />
    <Table.Column
      width={100}
      title="Дата подачи"
      dataIndex="Appeals.submissionDate"
      render={(d) => formatDate(d)}
    />
    <Table.Column
      width={150}
      title="Время подачи"
      dataIndex="Appeals.submissionTime"
    />
    <Table.Column
      width={150}
      title="Дата закрытия обращения"
      dataIndex="Appeals.replyDate"
      render={(d) => (d ? moment(d).format("DD.MM.YYYY") : "В обработке")}
    />
  </Table>
);

const icons = {
  "Call-центр": "/icons/call-center.svg",
  "С портала": "/icons/website.svg",
  "Телеграмм бот": "/icons/telegram.svg",
  Facebook: "/icons/facebook.svg",
  "Из соц. сети": "/icons/instagram.svg",
  "Мобильное приложение": "/icons/smartphone.svg",
};
const cubejsApi = cubejs({ appId: 1 });
export default class AppealsCitizen extends Component {
  state = {
    range: [
      moment().startOf("month").utc(6).startOf("day"),
      moment().utc(6).endOf("day"),
    ],
    data1: [],
    data2: [],
    showModal: false,
    name: null,
    type: null,
    exportData: [],
    filtersData: {
      "Appeals.region": {
        key: "Appeals.region",
        title: "Регион",
        options: [],
        values: [],
      },
      "Appeals.district": {
        key: "Appeals.district",
        title: "Населенный пункт",
        options: [],
        values: [],
      },
    },
  };

  // Card hover effect
  handleHover = () => {};

  toggleShowModal = (e, name) => {
    let type = typeof e === "string" ? e : null;
    this.setState({ showModal: !this.state.showModal, type, name });
  };

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res
            .rawData()
            .map((d) => d[filterNames[i]])
            .filter((e) => e),
        },
      };
    });
    this.setState({ filtersData });
    this.getData();
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  handleRange = (val) => {
    this.setState({ range: val }, this.getData);
  };

  getData = () => {
    let filters = [
      {
        member: "Appeals.submissionDate",
        operator: "inDateRange",
        values: this.state.range,
      },
    ];
    let query = [
      {
        measures: ["Appeals.appealsNum"],
        timeDimensions: [],
        dimensions: ["Appeals.appealType"],
        renewQuery: true,
      },
      {
        measures: ["Appeals.appealsNum"],
        timeDimensions: [],
        dimensions: ["Appeals.appealType"],
        filters,
        renewQuery: true,
      },
      {
        measures: ["Appeals.appealsNum"],
        dimensions: ["Appeals.appealType", "Appeals.source"],
        filters: [
          {
            dimension: "Appeals.appealType",
            operator: "contains",
            values: ["Запрос информации"],
          },
          ...filters,
        ],
        order: {
          "Appeals.source": "asc",
        },
        renewQuery: true,
      },
      {
        measures: ["Appeals.appealsNum"],
        dimensions: ["Appeals.appealType", "Appeals.source"],
        filters: [
          {
            dimension: "Appeals.appealType",
            operator: "contains",
            values: ["Инцидент"],
          },
          ...filters,
        ],
        order: {
          "Appeals.source": "asc",
        },
        renewQuery: true,
      },
      {
        measures: ["Appeals.appealsNum"],
        dimensions: ["Appeals.appealType", "Appeals.source"],
        filters: [
          {
            dimension: "Appeals.appealType",
            operator: "contains",
            values: ["Обращение"],
          },
          ...filters,
        ],
        order: {
          "Appeals.source": "asc",
        },
        renewQuery: true,
      },
    ];
    query = query.map((e) => cubejsApi.load(e));
    Promise.all(query).then((r) => {
      this.setState({
        data1: r[0].rawData(),
        data2: r[1].rawData(),
        exportData: [...r[2].rawData(), ...r[3].rawData(), ...r[4].rawData()],
      });
    });
  };

  render() {
    const { range, data1, data2, filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    if (!data1[0]) return null;
    let filters = [
      {
        member: "Appeals.submissionDate",
        operator: "inDateRange",
        values: range,
      },
    ];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });
    return (
      <Row
        type="flex"
        style={{ margin: 0 }}
        gutter={20}
        className="appeals-citizen"
      >
        <FromStart
          icons={icons}
          detailQuery={detailQuery}
          renderDetail={renderDetail}
          fields={fields}
          data1={data1}
          toggleShowModal={this.toggleShowModal}
        />
        <Col span={12}>
          <div
            style={{
              background: "#323c48",
              padding: "10px",
            }}
          >
            <Row>
              <Col span={16} style={{ alignItems: "center" }}>
                <Row>
                  <Col span={6}>
                    <h2
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        paddingRight: 10,
                        textTransform: "uppercase",
                      }}
                    >
                      ЗА ПЕРИОД
                    </h2>
                  </Col>
                  <Col span={16}>
                    <DatePicker.RangePicker
                      onChange={this.handleRange}
                      separator="—"
                      value={range}
                      showTime={{
                        secondStep: 60,
                        minuteStep: 15,
                      }}
                      allowClear={false}
                      size="small"
                      style={{
                        marginRight: "auto",
                        marginBottom: "5px",
                        width: "100%",
                      }}
                    />
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={6}></Col>
                  {filterNames.map((f, i) => {
                    const filter = filtersData[f];
                    return (
                      <Col
                        span={8}
                        style={{ textAlign: i === 0 ? "left" : "right" }}
                      >
                        <Select
                          mode="multiple"
                          placeholder={`${filter.title}`}
                          onChange={(value) =>
                            this.applyFilters(filter.key, value)
                          }
                          size="small"
                          allowClear
                          style={{ width: "98%" }}
                        >
                          {filter.options.map((val) => (
                            <Select.Option value={val} key={val}>
                              {val}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col
                span={8}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                <ExportExcel
                  filename={`Отчет по источникам обращений (${
                    filtersData["Appeals.region"].values.length !== 0
                      ? filtersData["Appeals.region"].values
                      : "Карагандинская область"
                  }, ${
                    filtersData["Appeals.district"].values.length !== 0
                      ? filtersData["Appeals.district"].values
                      : ""
                  }) за ${range[0].format(
                    "YYYY-MM-DD HH:mm:ss"
                  )} – ${range[1].format("YYYY-MM-DD HH:mm:ss")}`}
                  data={this.state.exportData}
                  style={{ paddingRight: "10px" }}
                  fields={[
                    {
                      dataIndex: "Appeals.appealType",
                      title: "Тип",
                    },
                    {
                      dataIndex: "Appeals.source",
                      title: "Источник",
                    },
                    {
                      dataIndex: "Appeals.appealsNum",
                      title: "Количество",
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row type="flex">
              <Col span={10} style={{ marginBottom: 30 }}>
                <QueryRenderer
                  query={{
                    measures: ["Appeals.appealsNum"],
                    timeDimensions: [],
                    dimensions: ["Appeals.appealType"],
                    filters,
                    renewQuery: true,
                  }}
                  cubejsApi={cubejsApi}
                  render={({ resultSet }) => {
                    if (!resultSet) return <LoadingCss />;
                    let sum = resultSet
                      .rawData()
                      .reduce((a, b) => a + Number(b["Appeals.appealsNum"]), 0);
                    const data = resultSet.rawData().map((e) => ({
                      ...e,
                      percent: ((+e["Appeals.appealsNum"] / sum) * 100).toFixed(
                        2
                      ),
                    }));
                    return (
                      <>
                        <PieChart
                          colorList={colors}
                          loading={!resultSet}
                          id="second"
                          showLegend={false}
                          totalFontSize={16}
                          data={data}
                          handleClick
                          nameKey="Appeals.appealType"
                          dataKey="Appeals.appealsNum"
                          showTotal
                          height="250px"
                          cubejsApi={cubejsApi}
                          query={detailQuery}
                          modalFilters={filters}
                          renderDetail={renderDetail}
                          excelFields={fields}
                          exportExcel
                        />
                        <div className="pie-legend">
                          <ul>
                            {data.map((e, i) => (
                              <li key={i}>
                                <span
                                  style={{ backgroundColor: colors[i] }}
                                ></span>
                                {e["Appeals.appealType"]}:
                                <b>
                                  {numberWithCommas(
                                    e["Appeals.appealsNum"],
                                    " "
                                  )}{" "}
                                  — {e.percent}%
                                </b>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </>
                    );
                  }}
                />
              </Col>
              <Col span={14}>
                {data2[0] && (
                  <Row gutter={10}>
                    <Col span={8}>
                      <div className="title-appeal">
                        <div>
                          <h4>Запрос информации:</h4>
                        </div>
                        <div>
                          <h4>
                            {
                              data2.find(
                                (e) =>
                                  e["Appeals.appealType"] ===
                                  "Запрос информации"
                              )["Appeals.appealsNum"]
                            }
                          </h4>
                        </div>
                      </div>
                      <QueryRenderer
                        query={{
                          measures: ["Appeals.appealsNum"],
                          timeDimensions: [],
                          dimensions: ["Appeals.appealType", "Appeals.source"],
                          filters: [
                            {
                              dimension: "Appeals.appealType",
                              operator: "equals",
                              values: ["Запрос информации"],
                            },
                            ...filters,
                          ],
                          order: {
                            "Appeals.source": "asc",
                          },
                          renewQuery: true,
                        }}
                        cubejsApi={cubejsApi}
                        render={({ resultSet }) => {
                          return resultSet ? (
                            resultSet.rawData().map((item, index) => (
                              <Card
                                onClick={() => {
                                  this.toggleShowModal(
                                    item["Appeals.source"],
                                    "Запрос информации"
                                  );
                                }}
                                key={index}
                                style={{ backgroundColor: "#2A323C" }}
                                className="appeal-numbers"
                              >
                                <Statistic
                                  title={item["Appeals.source"]}
                                  value={item["Appeals.appealsNum"]}
                                  groupSeparator="&thinsp;"
                                  valueStyle={{ fontSize: "16px" }}
                                  //valueStyle={{ color: "#3f8600" }}
                                  prefix={
                                    <img
                                      alt="img"
                                      style={{
                                        width: "20px",
                                        marginBottom: 5,
                                        marginRight: 5,
                                      }}
                                      src={"." + icons[item["Appeals.source"]]}
                                    />
                                  }
                                />
                              </Card>
                            ))
                          ) : (
                            <p>loading</p>
                          );
                        }}
                      />
                    </Col>
                    <Col span={8}>
                      <div className="title-appeal">
                        <div>
                          <h4>Инцидент:</h4>
                        </div>
                        <div>
                          <h4>
                            {data2.find(
                              (e) => e["Appeals.appealType"] === "Инцидент"
                            ) &&
                              data2.find(
                                (e) => e["Appeals.appealType"] === "Инцидент"
                              )["Appeals.appealsNum"]}
                          </h4>
                        </div>
                      </div>
                      <QueryRenderer
                        query={{
                          measures: ["Appeals.appealsNum"],
                          timeDimensions: [],
                          dimensions: ["Appeals.appealType", "Appeals.source"],
                          filters: [
                            {
                              dimension: "Appeals.appealType",
                              operator: "contains",
                              values: ["Инцидент"],
                            },
                            ...filters,
                          ],
                          order: {
                            "Appeals.source": "asc",
                          },
                          renewQuery: true,
                        }}
                        cubejsApi={cubejsApi}
                        render={({ resultSet }) => {
                          return resultSet ? (
                            resultSet.rawData().map((item, index) => (
                              <Card
                                onClick={() => {
                                  this.toggleShowModal(
                                    item["Appeals.source"],
                                    "Инцидент"
                                  );
                                }}
                                key={index}
                                style={{ backgroundColor: "#2A323C" }}
                                className="appeal-numbers"
                              >
                                <Statistic
                                  title={item["Appeals.source"]}
                                  value={item["Appeals.appealsNum"]}
                                  groupSeparator="&thinsp;"
                                  valueStyle={{ fontSize: "16px" }}
                                  //valueStyle={{ color: "#3f8600" }}
                                  prefix={
                                    <img
                                      alt="img"
                                      style={{
                                        width: "20px",
                                        marginBottom: 5,
                                        marginRight: 5,
                                      }}
                                      src={"." + icons[item["Appeals.source"]]}
                                    />
                                  }
                                />
                              </Card>
                            ))
                          ) : (
                            <p>loading</p>
                          );
                        }}
                      />
                    </Col>
                    <Col span={8}>
                      <div className="title-appeal">
                        <div>
                          <h4>Обращение:</h4>
                        </div>
                        <div>
                          <h4>
                            {data2.find(
                              (e) => e["Appeals.appealType"] === "Обращение"
                            ) &&
                              data2.find(
                                (e) => e["Appeals.appealType"] === "Обращение"
                              )["Appeals.appealsNum"]}
                          </h4>
                        </div>
                      </div>
                      <QueryRenderer
                        query={{
                          measures: ["Appeals.appealsNum"],
                          timeDimensions: [],
                          dimensions: ["Appeals.appealType", "Appeals.source"],
                          filters: [
                            {
                              dimension: "Appeals.appealType",
                              operator: "contains",
                              values: ["Обращение"],
                            },
                            ...filters,
                          ],
                          order: {
                            "Appeals.source": "asc",
                          },
                          renewQuery: true,
                        }}
                        cubejsApi={cubejsApi}
                        render={({ resultSet }) => {
                          return resultSet ? (
                            resultSet.rawData().map((item, index) => (
                              <Card
                                onClick={() => {
                                  this.toggleShowModal(
                                    item["Appeals.source"],
                                    "Обращение"
                                  );
                                }}
                                key={index}
                                style={{ backgroundColor: "#2A323C" }}
                                className="appeal-numbers"
                              >
                                <Statistic
                                  title={item["Appeals.source"]}
                                  value={item["Appeals.appealsNum"]}
                                  groupSeparator="&thinsp;"
                                  valueStyle={{ fontSize: "16px" }}
                                  prefix={
                                    <img
                                      alt="img"
                                      style={{
                                        width: "20px",
                                        marginBottom: 5,
                                        marginRight: 5,
                                      }}
                                      src={"." + icons[item["Appeals.source"]]}
                                    />
                                  }
                                />
                              </Card>
                            ))
                          ) : (
                            <p>loading</p>
                          );
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </Col>
        <CustomModal
          type={this.state.type}
          name={this.state.name}
          showModal={this.state.showModal}
          toggleShowModal={this.toggleShowModal}
          filter={this.state.filter}
          filters={{
            member: "Appeals.submissionDate",
            operator: "inDateRange",
            values: range,
          }}
        />
      </Row>
    );
  }
}
