import React from "react";
import { Modal, Tooltip } from "antd";
import { QueryRenderer } from "@cubejs-client/react";
import cubejs from "../../../cube";
import moment from "moment";
import { Table } from "antd";
import { formatDate } from "../../../utils/helpers";
import ExportExcel from "../../../components/ExportExcel";
const cubejsApi = cubejs({ appId: 1 });

const renderDetail = (items, type, filters) => {
  const range = filters.values;
  const renderRowColorAndWordBreak = (text, record, maxWidth) => {
    return {
      props: {
        style: { color: record.color },
      },
      children: (
        <Tooltip title={text}>
          <div
            style={{
              whiteSpace: "pre",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: maxWidth,
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    };
  };
  return (
    <>
      <ExportExcel
        style={{
          position: "absolute",
          right: "70px",
          zIndex: "12",
          top: "15px",
        }}
        filename={`Количество обращений из источника - ${type} с ${moment(
          range[0]
        ).format("DD/MM/YY")} по ${moment(range[1]).format("DD/MM/YY")}`}
        data={items}
        fields={[
          { dataIndex: "Appeals.appealNum", title: "Рег. номер обращения" },
          { dataIndex: "Appeals.appealType", title: "Тип" },
          { dataIndex: "Appeals.expired", title: "Истек" },
          { dataIndex: "Appeals.answerType", title: "Способ ответа" },
          { dataIndex: "Appeals.executor", title: "Исполнитель" },
          { dataIndex: "Appeals.appealText", title: "Текст обращения" },
          { dataIndex: "Appeals.category", title: "Категория" },
          { dataIndex: "Appeals.subCategory", title: "Подкатегория" },
          { dataIndex: "Appeals.submissionDate", title: "Дата подачи" },
          { dataIndex: "Appeals.submissionTime", title: "Время подачи" },
          { dataIndex: "Appeals.replyDate", title: "Дата закрытия обращения" },
        ]}
      />
      <Table
        rowClassName={(record) =>
          record["Appeals.expired"] === "Просрочено" ? "row error" : ""
        }
        rowKey="Appeals.replyDate"
        dataSource={items}
        size="small"
        // scroll={{ x: 1300, y: "60vh" }}
        // scroll={{ x: '1500px',y: "60vh" }}
        scroll={{ y: "60vh", x: "max-content" }}
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
          locale: { items_per_page: "" },
        }}
      >
        <Table.Column title="№" dataIndex="id" />
        <Table.Column
          title="Рег. номер обращения"
          dataIndex="Appeals.appealNum"
          key="Appeals.appealNum"
          width={150}
        />
        <Table.Column width={100} title="Тип" dataIndex="Appeals.appealType" />
        {/* <Table.Column width={150} title="Тип подачи" dataIndex="Appeals.source" /> */}
        <Table.Column width={150} title="Истек" dataIndex="Appeals.expired" />
        <Table.Column
          width={150}
          title="Способ ответа"
          dataIndex="Appeals.answerType"
        />
        <Table.Column
          width={150}
          title="Исполнитель"
          dataIndex="Appeals.executor"
        />
        <Table.Column
          width={200}
          title="Категория"
          dataIndex="Appeals.category"
        />
        <Table.Column
          width={150}
          title="Подкатегория"
          dataIndex="Appeals.subCategory"
        />
        <Table.Column
          title="Текст обращения"
          dataIndex="Appeals.appealText"
          width={200}
          render={(text, record) =>
            renderRowColorAndWordBreak(text, record, "35ch")
          }
        />
        <Table.Column
          width={100}
          title="Дата подачи"
          dataIndex="Appeals.submissionDate"
          render={(d) => formatDate(d)}
        />
        <Table.Column
          width={150}
          title="Время подачи"
          dataIndex="Appeals.submissionTime"
        />
        <Table.Column
          width={150}
          title="Дата закрытия обращения"
          dataIndex="Appeals.replyDate"
          render={(d) => (d ? moment(d).format("DD.MM.YYYY") : "В обработке")}
        />
      </Table>
    </>
  );
};

const CustomModal = ({ type, showModal, toggleShowModal, filters, name }) => {
  return (
    <Modal
      title={`Количество обращений по разделу - ${name}, из источника - ${type} c ${moment(
        filters.values[0]
      ).format("DD.MM.YY")} по ${moment(filters.values[1]).format("DD.MM.YY")}`}
      width="96%"
      style={{
        marginTop: "10px",
      }}
      bodyStyle={{
        overflowY: "auto",
      }}
      footer={null}
      centered
      visible={showModal}
      onOk={toggleShowModal}
      onCancel={toggleShowModal}
    >
      <QueryRenderer
        query={{
          measures: ["Appeals.appealsNum"],
          dimensions: [
            "Appeals.appealNum",
            "Appeals.appealType",
            "Appeals.expired",
            "Appeals.answerType",
            "Appeals.executor",
            "Appeals.category",
            "Appeals.subCategory",
            "Appeals.submissionDate",
            "Appeals.replyDate",
            "Appeals.source",
            "Appeals.submissionTime",
          ],
          filters: [
            {
              dimension: "Appeals.appealType",
              operator: "equals",
              values: [name],
            },
            {
              dimension: "Appeals.source",
              operator: "equals",
              values: [type],
            },
            filters,
          ],
          order: {
            "Appeals.source": "asc",
          },
          renewQuery: true,
        }}
        cubejsApi={cubejsApi}
        render={({ resultSet }) => {
          if (resultSet) {
            let data = resultSet.rawData();

            return renderDetail(data, type, filters);
          }
          return (
            <p style={{ padding: "10px", textAlign: "center", color: "#fff" }}>
              loading...
            </p>
          );
        }}
      />
    </Modal>
  );
};

export default CustomModal;
