import React, { Component } from "react";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";
import {
  DatePicker,
  Table,
  Select,
  Statistic,
  Skeleton,
  Row,
  Col,
  Card,
  Radio,
} from "antd";
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";

import ExportExcel from "../../components/ExportExcel";
import { Funnel, PieChart } from "../../components/visualizations/";
import { generatePieData } from "../../utils/visualizations";

import cubejs from "../../cube";
import { formatDateTime, numberFormatter } from "../../utils/helpers";

const cubejsApi = cubejs({ appId: 3 });
const pageSizes = [10, 25, 50, 100];

const SUMS_DICT = {
  "MGZCube.planAmount": { title: "Сумма плана", color: "#67B7DC" },
  "MGZCube.contractAmmount": { title: "Сумма договоров", color: "#F7941E" },
  "MGZCube.notPayed": { title: "Не оплачено", color: "#F44336" },
  "MGZCube.factAmmount": { title: "Оплачено", color: "#FBC02D" },
  "MGZCube.diff": { title: "Экономия", color: "#388E3C" },
};

class MGZ extends Component {
  state = {
    filtersData: {
      "MGZCube.contractStatus": {
        key: "MGZCube.contractStatus",
        title: "Статус договора ",
        options: [],
        values: [],
      },
      "MGZCube.administrator": {
        key: "MGZCube.administrator",
        title: "Администратор бюджетной программы",
        options: [],
        values: [],
      },
    },
    range: [moment().subtract(7, "d"), moment()],
    radio: "a",
  };

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({ filtersData });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  radioChange = (value) => {
    this.setState({
      radio: value.target.value,
    });
  };

  render() {
    const { filtersData, range, radio } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [
      {
        member: "MGZCube.signDate",
        operator: "inDateRange",
        values: range,
      },
    ];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values,
          },
        ];
      }
    });

    return (
      <div
        style={{
          padding: 10,
        }}
      >
        <h1 className="page-title">
          Мониторинг государственных закупок данные с{" "}
          <span style={{ color: "#ff9900" }}>
            {range[0].format("DD.MM.YYYY")}{" "}
            <span style={{ color: "#fff" }}>по </span>
            {range[1].format("DD.MM.YYYY")}
          </span>
        </h1>

        <Row gutter={20} style={{ marginBottom: 20 }}>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <Col span={6} key={i}>
                <Select
                  mode="multiple"
                  placeholder={filter.title}
                  onChange={(values) => this.applyFilters(filter.key, values)}
                  size="small"
                  allowClear
                  style={{ width: "100%" }}
                >
                  {filter.options.map((val) => (
                    <Select.Option value={val} key={val}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            );
          })}

          <Col span={4}>
            <h4>Период</h4>
            <DatePicker.RangePicker
              onChange={(range) => {
                this.setState({ range });
              }}
              value={range}
              size="small"
              style={{ marginBottom: 12 }}
            />
          </Col>
        </Row>

        <Radio.Group
          defaultValue="a"
          buttonStyle="solid"
          onChange={this.radioChange}
          className="mb-10"
          size="small"
        >
          <Radio.Button value="a">Дэшборд</Radio.Button>
          <Radio.Button value="b">Сводный</Radio.Button>
        </Radio.Group>
        {radio === "a" && (
          <>
            <Row gutter={20} style={{ marginBottom: 20 }}>
              <Col span={12}>
                <div className="card">
                  <QueryRenderer
                    query={{
                      measures: [
                        "MGZCube.planAmount",
                        "MGZCube.contractAmmount",
                        "MGZCube.notPayed",
                        "MGZCube.factAmmount",
                        "MGZCube.diff",
                      ],
                      filters,
                    }}
                    cubejsApi={cubejsApi}
                    render={({ resultSet }) => {
                      if (!resultSet) return <Skeleton />;
                      let data = resultSet.rawData()[0];
                      data = Object.keys(data).map((key) => ({
                        category: SUMS_DICT[key].title,
                        value: data[key],
                        color: SUMS_DICT[key].color,
                      }));
                      return <Funnel id="CustomFunnel" data={data} />;
                    }}
                  />
                </div>
              </Col>
              <Col span={7}>
                <div className="card">
                  {/* <div className="title">Статусы договоров</div> */}
                  <QueryRenderer
                    query={{
                      measures: ["MGZCube.amount"],
                      dimensions: ["MGZCube.finalStatus"],
                      filters,
                    }}
                    cubejsApi={cubejsApi}
                    render={({ resultSet }) => {
                      return (
                        <PieChart
                          loading={!resultSet}
                          id="MGZCubeStatusPie"
                          {...generatePieData(resultSet)}
                        />
                      );
                    }}
                  />
                </div>
              </Col>
              <Col span={5}>
                <Card size="small">
                  <QueryRenderer
                    query={{
                      measures: [
                        "MGZCube.planAmount",
                        "MGZCube.contractAmmount",
                        "MGZCube.contractCount",
                        "MGZCube.planCount",
                      ],
                      filters,
                    }}
                    cubejsApi={cubejsApi}
                    render={({ resultSet }) => {
                      if (!resultSet) return <Skeleton />;
                      let data = resultSet.rawData();
                      return (
                        <div>
                          <Statistic
                            title="Сумма по планам"
                            value={numberFormatter(
                              data[0]["MGZCube.planAmount"]
                            )}
                            style={{
                              fontSize: 16,
                              textAlign: "center",
                              marginBottom: 10,
                            }}
                            valueStyle={{ color: "#fff" }}
                          />
                          <Statistic
                            title="Сумма по договорам"
                            value={numberFormatter(
                              Number(
                                data[0]["MGZCube.contractAmmount"]
                              ).toFixed(0)
                            )}
                            style={{ textAlign: "center", marginBottom: 10 }}
                            valueStyle={{ color: "#fff" }}
                          />
                          <Statistic
                            title="Кол-во заключенных договоров"
                            value={numberFormatter(
                              data[0]["MGZCube.contractCount"]
                            )}
                            style={{ textAlign: "center", marginBottom: 10 }}
                            valueStyle={{ color: "#fff" }}
                          />
                          <Statistic
                            title="Кол-во пунктов плана"
                            value={numberFormatter(
                              data[0]["MGZCube.planCount"]
                            )}
                            style={{ textAlign: "center", marginBottom: 10 }}
                            valueStyle={{ color: "#fff" }}
                          />
                        </div>
                      );
                    }}
                  />
                </Card>
              </Col>
            </Row>

            <QueryRenderer
              query={{
                measures: [
                  "MGZCube.planAmount",
                  "MGZCube.contractAmmount",
                  "MGZCube.planCount",
                  "MGZCube.contractCount",
                ],
                dimensions: ["MGZCube.administrator"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return <Skeleton />;
                const data = resultSet.rawData();
                return (
                  <>
                    <Row type="flex" justify="end" style={{ marginBottom: 10 }}>
                      <ExportExcel
                        filename="МГЗ"
                        data={data}
                        fields={[
                          {
                            title: "Администратор бюджетной программы",
                            dataIndex: "MGZCube.administrator",
                          },
                          {
                            title: "Сумма по планам",
                            dataIndex: "MGZCube.planAmount",
                          },
                          {
                            title: "Сумма по договорам",
                            dataIndex: "MGZCube.contractAmmount",
                          },
                          {
                            title: "Кол-во пунктов плана",
                            dataIndex: "MGZCube.planCount",
                          },
                          {
                            title: "Кол-во договоров",
                            dataIndex: "MGZCube.contractCount",
                          },
                        ]}
                      />
                    </Row>
                    <div
                      style={{
                        height: 420,
                        overflowY: "scroll",
                        marginBottom: 40,
                      }}
                    >
                      <Table
                        loading={!resultSet}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                        size="small"
                      >
                        <Table.Column
                          title="Администратор отчетности"
                          dataIndex="MGZCube.administrator"
                          width={100}
                        />
                        <Table.Column
                          title="Сумма по планам"
                          dataIndex="MGZCube.planAmount"
                          width={100}
                        />
                        <Table.Column
                          title="Сумма по договорам"
                          dataIndex="MGZCube.contractAmmount"
                          width={100}
                        />
                        <Table.Column
                          title="Кол-во пунктов плана"
                          dataIndex="MGZCube.planCount"
                          width={100}
                        />
                        <Table.Column
                          title="Кол-во договоров"
                          dataIndex="MGZCube.contractCount"
                          width={100}
                        />
                      </Table>
                    </div>
                  </>
                );
              }}
            />
          </>
        )}

        {radio === "b" && (
          <div style={{ marginTop: 20 }}>
            <QueryRenderer
              query={{
                measures: [
                  "MGZCube.amount",
                  "MGZCube.sum1",
                  "MGZCube.sum2",
                  "MGZCube.sum3",
                  "MGZCube.quantity",
                  "MGZCube.factSumWnds",
                  "MGZCube.planCountN",
                  "MGZCube.price",
                  "MGZCube.planAmount",
                  "MGZCube.contractAmmount",
                  "MGZCube.planCount",
                  "MGZCube.contractCount",
                ],
                dimensions: [
                  // 'MGZCube.administrator',
                  "MGZCube.one",
                  "MGZCube.two",
                  "MGZCube.three",
                  "MGZCube.customer",
                  "MGZCube.supplier",
                  "MGZCube.extraDescRu",
                  "MGZCube.contract",
                  "MGZCube.planStatus",
                  "MGZCube.planExec",
                  "MGZCube.subjectType",
                  "MGZCube.contractNumberSys",
                  "MGZCube.planStatus",
                  "MGZCube.connSt",
                  "MGZCube.planTradeMethod",
                  "MGZCube.factTradeMethod",
                  //
                  "MGZCube.planNumber",
                  "MGZCube.planFinYear",
                  "MGZCube.unit",
                  "MGZCube.signDate",
                  "MGZCube.planExecDate",
                  "MGZCube.mainConSignDate",
                ],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return <Skeleton />;
                const data = resultSet.rawData();
                return (
                  <DataGrid
                    dataSource={data}
                    allowColumnReordering
                    showBorders
                    scrolling={{
                      mode: "virtual",
                      scrollByContent: true,
                    }}
                    wordWrapEnabled
                    height={560}
                  >
                    <GroupPanel visible />
                    <SearchPanel visible highlightCaseSensitive />
                    <Grouping autoExpandAll={false} />
                    <Column
                      caption="Администратор"
                      dataField="MGZCube.one"
                      calculateCellValue={(rawData) => rawData["MGZCube.one"]}
                      groupIndex={0}
                    />

                    <Column
                      caption="Администратор отчетности"
                      dataField="MGZCube.two"
                      calculateCellValue={(rawData) => rawData["MGZCube.two"]}
                      groupIndex={1}
                    />

                    <Column
                      caption="Районный отдел"
                      dataField="MGZCube.three"
                      calculateCellValue={(rawData) => rawData["MGZCube.three"]}
                      groupIndex={2}
                    />

                    <Column
                      caption="Заказчик"
                      dataField="MGZCube.customer"
                      calculateCellValue={(rawData) =>
                        rawData["MGZCube.customer"]
                      }
                      groupIndex={3}
                    />

                    <Column
                      caption="Финансовый год плана"
                      dataField="MGZCube.planFinYear"
                      calculateCellValue={(rawData) =>
                        rawData["MGZCube.planFinYear"]
                      }
                      width={100}
                    />
                    <Column
                      caption="№ п/п"
                      dataField="MGZCube.planNumber"
                      calculateCellValue={(rawData) =>
                        rawData["MGZCube.planNumber"]
                      }
                      width={100}
                    />
                    <Column
                      caption="Мероприятие"
                      dataField="MGZCube.extraDescRu"
                      calculateCellValue={(rawData) =>
                        rawData["MGZCube.extraDescRu"]
                      }
                      width={300}
                    />
                    <Column caption="СОГЛАСНО ПЛАНА ЗАКУПОК">
                      <Column
                        caption="Способ государственной закупки согласно утвержденному плану государственных закупок"
                        dataField="MGZCube.planTradeMethod"
                        calculateCellValue={(rawData) =>
                          rawData["MGZCube.planTradeMethod"]
                        }
                        width={300}
                      />
                      <Column
                        caption="Единица измерения"
                        dataField="MGZCube.unit"
                        calculateCellValue={(rawData) =>
                          rawData["MGZCube.unit"]
                        }
                        width={100}
                      />
                      <Column
                        caption="Количество"
                        dataField="MGZCube.planCountN"
                        calculateCellValue={(rawData) =>
                          rawData["MGZCube.planCountN"]
                        }
                        width={100}
                      />
                      <Column
                        caption="Цена за единицу"
                        dataField="MGZCube.price"
                        calculateCellValue={(rawData) =>
                          rawData["MGZCube.price"]
                        }
                        width={100}
                      />
                      <Column
                        caption="Общая сумма"
                        dataField="MGZCube.amount"
                        calculateCellValue={(rawData) =>
                          rawData["MGZCube.amount"]
                        }
                        width={100}
                      />
                    </Column>
                    <Column
                      caption="Наименование и БИН поставщика"
                      dataField="MGZCube.supplier"
                      calculateCellValue={(rawData) =>
                        rawData["MGZCube.supplier"]
                      }
                      width={300}
                    />
                    <Column caption="ФАКТ ЗАКУПА">
                      <Column
                        caption="Фактический способ закупки"
                        dataField="MGZCube.factTradeMethod"
                        calculateCellValue={(rawData) =>
                          rawData["MGZCube.factTradeMethod"]
                        }
                        width={300}
                      />
                      <Column
                        caption="Количество"
                        dataField="MGZCube.quantity"
                        calculateCellValue={(rawData) =>
                          rawData["MGZCube.quantity"]
                        }
                        width={100}
                      />
                      <Column
                        caption="По факту заключенного договора (Без НДС)"
                        dataField="MGZCube.contractAmmount"
                        calculateCellValue={(rawData) =>
                          rawData["MGZCube.contractAmmount"]
                        }
                        width={100}
                      />
                      <Column
                        caption="Дата подписания"
                        dataField="MGZCube.signDate"
                        calculateCellValue={(rawData) =>
                          formatDateTime(rawData["MGZCube.signDate"])
                        }
                        width={100}
                      />
                      <Column
                        caption="Планируемая дата исполнения"
                        dataField="MGZCube.planExecDate"
                        calculateCellValue={(rawData) =>
                          formatDateTime(rawData["MGZCube.planExecDate"])
                        }
                        width={100}
                      />
                    </Column>
                    <Column
                      caption="Оплачено по договору с НДС"
                      dataField="MGZCube.factSumWnds"
                      calculateCellValue={(rawData) =>
                        rawData["MGZCube.factSumWnds"]
                      }
                      width={180}
                    />
                    <Column
                      caption="Дата подписания основного договора"
                      dataField="MGZCube.mainConSignDate"
                      calculateCellValue={(rawData) =>
                        formatDateTime(rawData["MGZCube.mainConSignDate"])
                      }
                      width={200}
                    />

                    <Pager allowedPageSizes={pageSizes} showPageSizeSelector />
                    <Paging defaultPageSize={1000} pageSize={1000} />
                  </DataGrid>
                );
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default MGZ;
