import React from "react";
import { Icon, List } from "antd";
import moment from "moment";

const Item = (props) => {
  let { code, item } = props;

  return (
    <List.Item>
      <span className="date">
        <Icon type="calendar" style={{ marginRight: 8 }} />
        {moment(item[code + ".dateTime"]).format("DD/MM/YY HH:mm")}
      </span>
      <h3>{item[code + ".category"]}</h3>
      <small>
        {code === "AmbulanceView"
          ? item[code + ".subcategory"]
          : item[code + ".subCategory"]}
      </small>
      <div className="article-text">{item[code + ".comment"]}</div>
    </List.Item>
  );
};

export default Item;
