import React, { Component } from "react";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";
import { Col } from "antd";
import { rangeList } from "../constants";
import cubejs from "../cube";

// import ExportExcel from "../components/ExportExcel";

import {
  SingleNumber,
  VerticalBarChart as VertBarChart,
  HorizontalBarChart as HorizonBarChart,
  LineChart,
} from "../components/visualizations";
import {
  getSingleValue,
  generateBarData,
  generateLineData,
} from "../utils/visualizations";
import { MainWrapper } from "../components/newDesign/MainWrapper";
import { TabMainTitle } from "../components/newDesign/TabMainTitle";
import { FilterWrapper } from "../components/newDesign/FilterWrapper";
import { FilterItem } from "../components/newDesign/FilterItem";
import { SelectList } from "../components/newDesign/SelectList";
import { DatePickerR } from "../components/newDesign/DatePicker/DatePickerR";
import { RowWrapper } from "../components/newDesign/RowWrapper";
import { ChartCard } from "../components/newDesign/ChartCard";
import {
  CHART_MAIN_COLOR,
  CHART_SECONDARY_COLOR,
} from "../components/newDesign/utils";

const cubejsApi = cubejs({ appId: 1 });

export default class PublicTransport extends Component {
  state = {
    filtersData: {
      "Transport.company": {
        key: "Transport.company",
        title: "Организация",
        options: [],
        values: [],
      },
      "Transport.number": {
        key: "Transport.number",
        title: "Номер маршрута",
        options: [],
        values: [],
      },
      "Transport.controlTime": {
        key: "Transport.controlTime",
        title: "Контрольное время",
        options: [],
        values: [],
      },
    },
    range: [moment().subtract(1, "y"), moment()],
  };

  changeRange = (value) =>
    this.setState({
      range: [moment(value), moment()],
    });

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({ filtersData });
  }

  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  render() {
    const { filtersData, range } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [
      {
        member: "Transport.date",
        operator: "inDateRange",
        values: range, //['2015-01-01', '2015-12-31']
      },
    ];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });

    return (
      <MainWrapper>
        <TabMainTitle>
          Диспетчеризация общественного транспорта на период c{" "}
          <span className="date-period">
            {range[0].format("DD.MM.YYYY")}{" "}
            <span style={{ color: "#fff" }}>по </span>{" "}
            {range[1].format("DD.MM.YYYY")}
          </span>
        </TabMainTitle>
        <FilterWrapper>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <FilterItem>
                <SelectList
                  label={filter.title + ":"}
                  mode="multiple"
                  onChange={(value) => this.applyFilters(filter.key, value)}
                  size="small"
                  allowClear
                  list={filter.options}
                />
              </FilterItem>
            );
          })}

          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={(range) => {
                this.setState({ range });
              }}
              value={range}
              size="small"
              separator="—"
            />
          </FilterItem>

          <FilterItem>
            <SelectList
              label={"Шаблон:"}
              defaultValue={rangeList["Неделя"].toString()}
              onChange={(value) => this.changeRange(value)}
              size="small"
              showKey="title"
              valueKey="id"
              list={Object.entries(rangeList).map(([key, value]) => ({
                id: value.toString(),
                title: key,
              }))}
            />
          </FilterItem>
          {/*<Col span={2}>
            <QueryRenderer
              query={{
                dimensions: [
                  "CrimeKgf.crname",
                  "CrimeKgf.hardcode",
                  "CrimeKgf.article",
                  "CrimeKgf.dateSover",
                  "CrimeKgf.dateVozb",
                  "CrimeKgf.udv",
                  "CrimeKgf.organ",
                  "CrimeKgf.street",
                  "CrimeKgf.flat"
                ],
                filters,
                timeDimensions: [
                  {
                    dimension: "CrimeKgf.dateSover",
                    dateRange: range,
                    granularity: "month"
                  }
                ]
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return null;
                return (
                  <ExportExcel
                    filename="Преступления"
                    data={resultSet.rawData()}
                    fields={[
                      {
                        title: "Категория",
                        dataIndex: "CrimeKgf.crname"
                      },
                      {
                        title: "Тяжесть",
                        dataIndex: "CrimeKgf.hardcode"
                      },
                      {
                        title: "Статья",
                        dataIndex: "CrimeKgf.article"
                      },
                      {
                        title: "Дата совершения УД",
                        dataIndex: "CrimeKgf.dateSover"
                      },
                      {
                        title: "Дата возбуждения УД",
                        dataIndex: "CrimeKgf.dateVozb"
                      },
                      {
                        title: "Номер КУИ",
                        dataIndex: "CrimeKgf.udv"
                      },
                      {
                        title: "Орган",
                        dataIndex: "CrimeKgf.organ"
                      },
                      {
                        title: "Улица",
                        dataIndex: "CrimeKgf.street"
                      },
                      { title: "Дом", dataIndex: "CrimeKgf.flat" }
                    ]}
                  />
                );
              }}
            />
          </Col>*/}
        </FilterWrapper>

        <RowWrapper>
          <ChartCard title="Диспетчеризация общественного транспорта">
            <QueryRenderer
              query={{
                measures: ["Transport.sumPlan", "Transport.sumFact"],
                dimensions: ["Transport.controlTime"],
                filters,
                order: {
                  "Transport.controlTime": "desc",
                },
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <HorizonBarChart
                    loading={!resultSet}
                    id="HorizonBarChart"
                    {...generateBarData(resultSet)}
                    rotate
                    height="900px"
                    colorsArr={[CHART_MAIN_COLOR, CHART_SECONDARY_COLOR]}
                  />
                );
              }}
            />
          </ChartCard>

          <Col span={12}>
            <ChartCard>
              <QueryRenderer
                query={{
                  measures: ["Transport.avgPercent"],
                  filters,
                }}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  return (
                    <div style={{ padding: 16 }}>
                      <SingleNumber title="Средняя регулярность выхода на учетные часы">
                        {resultSet &&
                          Number(
                            getSingleValue(resultSet, "Transport.avgPercent")
                          ).toFixed(1)}
                      </SingleNumber>
                    </div>
                  );
                }}
              />
            </ChartCard>

            <ChartCard title="Общая динамика">
              <QueryRenderer
                query={{
                  measures: ["Transport.sumPlan", "Transport.sumFact"],
                  dimensions: ["Transport.date.day"],
                  filters,
                }}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  return (
                    <LineChart
                      loading={!resultSet}
                      id="PublickTransportGeneralDynamics"
                      {...generateLineData(resultSet)}
                      dateAxis
                      rotate
                      height="400px"
                      scrollbarWidth={75}
                      scrollbarHeight={4}
                      colorsArr={[CHART_MAIN_COLOR, CHART_SECONDARY_COLOR]}
                    />
                  );
                }}
              />
            </ChartCard>

            <ChartCard title="Количество обращений в приемный покой">
              <QueryRenderer
                query={{
                  measures: ["Transport.sumPlan", "Transport.sumFact"],
                  dimensions: ["Transport.company"],
                  filters,
                }}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  return (
                    <VertBarChart
                      loading={!resultSet}
                      id="PublicTransportMedicalAssistance"
                      {...generateBarData(resultSet)}
                      rotate
                      height="400px"
                      colorsArr={[CHART_MAIN_COLOR, CHART_SECONDARY_COLOR]}
                    />
                  );
                }}
              />
            </ChartCard>
          </Col>
        </RowWrapper>
      </MainWrapper>
    );
  }
}
