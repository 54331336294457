import React from "react";
import { List } from "antd";
import Item from "./Item";

const Lists = (props) => {
  const { dataSource, code } = props;

  return (
    <List
      itemLayout="vertical"
      pagination={{
        pageSize: 5,
      }}
      size="large"
      dataSource={dataSource}
      renderItem={(item) => {
        return <Item item={item} code={code} />;
      }}
    />
  );
};

export default Lists;
